import { makeStyles } from '@material-ui/core/styles';
import { COLORS_BY_LABEL } from 'features/command-center/constants';

import { SIZES } from './StastsItem.constants';

const VALUE_LABEL_STYLES = {
  large: {
    fontSize: '1.5rem',
    lineHeight: '2.25rem'
  },
  medium: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem'
  },
  small: {
    fontSize: '1.125rem',
    lineHeight: '1.25rem'
  }
};

export const useStyles = makeStyles(theme => ({
  subtitleLabel: {
    color: theme.palette.grey[700],
    fontSize: '0.875rem',
    fontWeight: '400 !important',
    lineHeight: '1.5rem'
  },
  titleLabel: {
    color: theme.palette.grey[700],
    fontSize: ({ size }) => (size === SIZES.small ? '0.75rem' : '0.875rem'),
    fontWeight: '500 !important',
    lineHeight: ({ size }) => (size === SIZES.small ? '20px' : '16px')
  },
  valueLabel: ({ size, valueColor, isClickEnabled }) => ({
    color: COLORS_BY_LABEL[valueColor] ?? valueColor,
    fontFamily: '"Kanit", sans-serif',
    fontWeight: '500 !important',
    textDecoration: isClickEnabled ? 'underline' : 'none',
    cursor: isClickEnabled ? 'pointer' : 'default',
    ...VALUE_LABEL_STYLES[size]
  })
}));
