import {
  HealthDistributionAtRiskConfig,
  HealthDistributionBehindConfig,
  HealthDistributionHeadCountConfig,
  HealthDistributionInTrainingConfig,
  HealthDistributionOnTrackConfig,
  HealthDistributionUnder3MonthsConfig
} from './components';

export const DRAWER_KEYS = Object.freeze({
  HealthDistributionHeadcount: 'HealthDistributionHeadcount',
  HealthDistributionInTraining: 'HealthDistributionInTraining',
  HealthDistributionAtRisk: 'HealthDistributionAtRisk',
  HealthDistributionBehind: 'HealthDistributionBehind',
  HealthDistributionUnder3Months: 'HealthDistributionUnder3Months',
  HealthDistributionOnTrack: 'HealthDistributionOnTrack'
});

export const DRAWERS_CONFIG = {
  [DRAWER_KEYS.HealthDistributionHeadcount]: HealthDistributionHeadCountConfig,
  [DRAWER_KEYS.HealthDistributionInTraining]: HealthDistributionInTrainingConfig,
  [DRAWER_KEYS.HealthDistributionAtRisk]: HealthDistributionAtRiskConfig,
  [DRAWER_KEYS.HealthDistributionBehind]: HealthDistributionBehindConfig,
  [DRAWER_KEYS.HealthDistributionUnder3Months]: HealthDistributionUnder3MonthsConfig,
  [DRAWER_KEYS.HealthDistributionOnTrack]: HealthDistributionOnTrackConfig
};
