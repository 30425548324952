// @flow

import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import { Widget } from 'features/command-center/components/shared';
import { DateRange } from 'features/command-center/components/shared/InlineFilters/components/DateRange';
import { getActivityTrendsHistory } from 'features/command-center/services';
import moment from 'moment';
import strings from 'strings';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { DateFormats } from 'UI/constants/defaults';
import { createCustomStaticRanges, getCurrentWeekRange } from 'UI/utils';

import { DEFAULT_OPTIONS } from './ActivityTrends.constants';
import { formatCallsData, generateChartData, insertShadedData } from './ActivityTrends.utils';

const defaultRange = getCurrentWeekRange();

export const ActivityTrends = ({ requestParams = {} }) => {
  const [dateRange, setDateRange] = useState(defaultRange);
  const queryParams = {
    startDate: moment(dateRange.startDate).format(DateFormats.QueryFormat),
    endDate: moment(dateRange.endDate).format(DateFormats.QueryFormat),
    metrics: 'calls',
    ...requestParams
  };

  const handleDateRangeChange = selectedDateRange => setDateRange(selectedDateRange);

  return (
    <Widget
      header={strings.commandCenter.activity.activityTrends.title}
      promises={[() => getActivityTrendsHistory(queryParams)]}
    >
      {([data]) => {
        const isEmpty = data.length === 0;
        const transformedData = formatCallsData(data);
        const chartData = generateChartData(transformedData);
        const shadedData = insertShadedData(chartData);

        const chartOptions = {
          ...DEFAULT_OPTIONS,
          vAxis: {
            ...DEFAULT_OPTIONS.vAxis,
            viewWindow: isEmpty ? { min: 1, max: 5 } : undefined,
            ticks: isEmpty ? [1, 2, 3, 4, 5] : undefined
          }
        };

        return (
          <>
            <DateRange
              DateRangerPickerProps={{
                staticRanges: createCustomStaticRanges(additionalPeriodRanges)
              }}
              onPeriodChange={handleDateRangeChange}
              defaultRange={dateRange}
            />
            <Chart
              chartType="ComboChart"
              data={shadedData}
              options={chartOptions}
              width="100%"
              height="400px"
              legendToggle
            />
          </>
        );
      }}
    </Widget>
  );
};
