// @flow

import type { Stat } from 'features/command-center/types';
import { formatNumber } from 'UI/utils';

import { activityTrendsTooltip } from '../../finance/finance.utils';

export const generateChartData = (data: Stat[]) => {
  const chartData = [
    [
      'Time',
      'Calls',
      { role: 'tooltip', type: 'string', p: { html: true } },
      'Time Off Calls',
      { role: 'tooltip', type: 'string', p: { html: true } }
    ]
  ];
  data.forEach((stat: Stat, index: number) => {
    const isNonWorkingHour = index < 7 || index > 18;
    const formatedValue = formatNumber(stat.value);
    const tooltip = activityTrendsTooltip(formatedValue, 'Calls');

    if (isNonWorkingHour) {
      chartData.push([stat.title, 0, tooltip, stat.value, tooltip]); // Non-working hours data
    } else {
      chartData.push([stat.title, stat.value, tooltip, 0, tooltip]); // Working hours data
    }
  });
  return chartData;
};

const getPeakActivityCount = (data: any[][]) => {
  return Math.max(...data.slice(1).map(row => Math.max(row[1], row[3])));
};

export const insertShadedData = (chartData: any[][]) => {
  const peakCallCount = getPeakActivityCount(chartData);
  const shadedValue = Math.ceil(peakCallCount * 1.1); // 10% percent more than the peak.

  const shadedData = chartData.map((row, index) => {
    if (index === 0) return [...row, 'Shaded Area 1', 'Shaded Area 2'];
    const shadedValue1 = index <= 8 ? shadedValue : 0; // Shade first 8 hours
    const shadedValue2 = index > 18 ? shadedValue : 0; // Shade last 8 hours
    return [...row, shadedValue1, shadedValue2];
  });
  return shadedData;
};

export const formatCallsData = apiData => {
  const result = Array.from({ length: 24 }, (_, i) => ({
    title: (i % 12 || 12).toString(),
    value: 0
  }));

  apiData.forEach(item => {
    const hour = parseInt(item.hourGroup.split(':')[0], 10);
    result[hour].value += item.totalCalls;
  });

  return result;
};
