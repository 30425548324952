import React from 'react';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';

const { healthDistributionInTraining } = strings.commandCenter.drawers;

export const HealthDistributionInTraining = () => {
  return <div>HealthDistributionInTraining</div>;
};

export const HealthDistributionInTrainingConfig = {
  title: healthDistributionInTraining.title,
  subHeader: (
    <>
      <Typography
        dangerouslySetInnerHTML={{
          __html: healthDistributionInTraining.subTitle.replace(
            '{healthStatus}',
            '<strong>In Training</strong>'
          )
        }}
      />
    </>
  ),
  component: HealthDistributionInTraining
};
