import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  CallsGauge,
  HealthDistribution,
  InMailsGauge,
  LayoutSkeleton,
  OverallPerformance,
  PDAByTenure,
  PeopleReachedGauge,
  PhoneTimeGauge,
  PlacementsStatsSummary,
  TeamLayout,
  WeeklyActivityStatsSummary,
  WeeklyInventoryGrowthStatsSummary,
  WeeklyProductivityStatsSummary
} from 'features/command-center';
import { DrawerManagerProvider } from 'features/command-center/contexts';
import { useRefreshStats } from 'features/command-center/hooks';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { When } from 'UI/components/atoms/When';
import { Endpoints } from 'UI/constants/endpoints';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { UIStatus } from 'UI/constants/status';

export const TeamOverview = ({ id, match }) => {
  const teamId = id ?? match?.params?.id;
  const requestParams = { team: teamId };
  const { refreshData, refreshKey } = useRefreshStats();
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const { state: weeksCatalogState } = useFetchWithStatusV2({
    endpoint: Endpoints.ToolsUsageReportWeeks
  });

  const weeks = weeksCatalogState?.results;
  const canDoDrillDown = checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterDrillDown);

  return (
    <DrawerManagerProvider>
      <TeamLayout teamId={teamId} onRefresh={refreshData}>
        <When condition={weeksCatalogState.status === UIStatus.Loading}>
          <LayoutSkeleton />
        </When>
        <When condition={weeksCatalogState.status !== UIStatus.Loading}>
          <Grid key={refreshKey} container spacing={4}>
            <Grid item xs={12}>
              <HealthDistribution requestParams={requestParams} hasDrillDown={canDoDrillDown} />
            </Grid>

            <Grid item xs={12}>
              <PlacementsStatsSummary requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <WeeklyActivityStatsSummary requestParams={requestParams} />
            </Grid>

            <Grid item xs={6}>
              <WeeklyProductivityStatsSummary requestParams={requestParams} />
            </Grid>

            <Grid item xs={6}>
              <WeeklyInventoryGrowthStatsSummary requestParams={requestParams} />
            </Grid>

            {checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterPDA) && (
              <Grid item xs={12}>
                <PDAByTenure requestParams={requestParams} />
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <PeopleReachedGauge
                requestParams={{ ...requestParams, groupBy: 'team' }}
                weeks={weeks}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InMailsGauge requestParams={{ ...requestParams, groupBy: 'team' }} weeks={weeks} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CallsGauge requestParams={{ ...requestParams, groupBy: 'team' }} weeks={weeks} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <PhoneTimeGauge requestParams={{ ...requestParams, groupBy: 'team' }} weeks={weeks} />
            </Grid>

            <Grid item xs={12}>
              <OverallPerformance teamId={teamId} />
            </Grid>
          </Grid>
        </When>
      </TeamLayout>
    </DrawerManagerProvider>
  );
};
