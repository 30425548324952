// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  ActivityTrends,
  AssignedInventoryStatsSummary,
  CallsTrends,
  FinancialStatsSummary,
  GoalsProgressInTime,
  InMailsTrends,
  LayoutSkeleton,
  PeopleReachedTrends,
  PhoneTimeTrends,
  PilMap,
  PlacementsStatsSummary,
  PlacementsTrends,
  RecruiterLayout,
  SendoutsTrends,
  WeeklyActivityStatsSummary,
  WeeklyInventoryGrowthStatsSummary,
  WeeklyProductivityStatsSummary
} from 'features/command-center';
import { DrawerManagerProvider } from 'features/command-center/contexts';
import { useRefreshStats } from 'features/command-center/hooks';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import { Endpoints } from 'UI/constants/endpoints';
import { UIStatus } from 'UI/constants/status';

export const RecruiterOverview = ({ id, match }) => {
  const recruiterId = id ?? match?.params?.id;
  const requestParams = { recruiter: recruiterId.toUpperCase() };

  const { refreshData, refreshKey } = useRefreshStats();

  const { state: weeksCatalogState } = useFetchWithStatusV2({
    endpoint: Endpoints.ToolsUsageReportWeeks
  });

  const weeks = weeksCatalogState?.results;

  return (
    <DrawerManagerProvider>
      <RecruiterLayout recruiterId={recruiterId} onRefresh={refreshData}>
        {weeksCatalogState.status === UIStatus.Loading ? (
          <LayoutSkeleton />
        ) : (
          <Grid key={refreshKey} container spacing={4}>
            <Grid item xs={12} md={8}>
              <PilMap requestParams={requestParams} />
            </Grid>

            <Grid item xs={12} md={4}>
              <AssignedInventoryStatsSummary requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <FinancialStatsSummary requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <PlacementsStatsSummary requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <GoalsProgressInTime requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <PlacementsTrends requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <SendoutsTrends requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <WeeklyActivityStatsSummary requestParams={requestParams} />
            </Grid>

            <Grid item xs={6}>
              <WeeklyProductivityStatsSummary requestParams={requestParams} />
            </Grid>

            <Grid item xs={6}>
              <WeeklyInventoryGrowthStatsSummary requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <PeopleReachedTrends weeks={weeks} requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <InMailsTrends weeks={weeks} requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <CallsTrends weeks={weeks} requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <PhoneTimeTrends weeks={weeks} requestParams={requestParams} />
            </Grid>

            <Grid item xs={12}>
              <ActivityTrends requestParams={{ ...requestParams }} />
            </Grid>
          </Grid>
        )}
      </RecruiterLayout>
    </DrawerManagerProvider>
  );
};
