import React from 'react';
import { StatsItemsCard } from 'features/command-center/components/shared';
import { getActivityWeekInventoryGrowth } from 'features/command-center/services';
import moment from 'moment';
import strings from 'strings';
import { DateFormats } from 'UI/constants/defaults';
import { getCurrentWeekRange } from 'UI/utils';

const formatDate = date => moment(date).format(DateFormats.QueryFormat);

const transformProps = params => {
  if (params.team) {
    return { teams: params.team };
  }
  if (params.recruiter) {
    return { employeeIds: params.recruiter };
  }
  return params;
};

export const WeeklyInventoryGrowthStatsSummary = ({ requestParams }) => {
  const week = getCurrentWeekRange();

  const params = {
    ...transformProps(requestParams),
    startDate: formatDate(week.startDate),
    endDate: formatDate(week.endDate)
  };

  return (
    <StatsItemsCard
      header={strings.commandCenter.activity.weeklyInventoryGrowthStatsSummary.title}
      promise={() => getActivityWeekInventoryGrowth(params)}
      subheader={strings.commandCenter.activity.weeklyInventoryGrowthStatsSummary.subtitle}
      textAlign="center"
    />
  );
};
