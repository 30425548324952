// @flow
import React, { useState } from 'react';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { When } from 'UI/components/atoms/When';
import ProfileAvatar from 'UI/components/molecules/ProfileAvatar';
import { RowActions } from 'UI/constants/defaults';
import { EntityType } from 'UI/constants/entityTypes';
import SvgAdd from 'UI/res/icons/filled/Add';

import { useStyles } from './styles';

type RecruiterDetailsCardProps = {
  recruiter: Object,
  assistant: Object,
  uiState: Object,
  loading: boolean,
  coach: Object,
  onReassignClick: () => void,
  isReassignButtonVisible: boolean
};

const RecruiterDetailsCard = ({
  recruiter,
  assistant,
  loading,
  coach,
  onReassignClick,
  isReassignButtonVisible,
  ...rest
}: RecruiterDetailsCardProps) => {
  const classes = useStyles();

  return (
    <When condition={!loading}>
      <div className={classes.avatarsContainer} {...rest}>
        <ProfileAvatar
          detail="Accountable"
          email={recruiter?.email}
          infoCardProps={{
            type: 'Accountable',
            details: [
              { text: coach?.full_name ? `Team: ${coach?.full_name}` : null },
              { text: recruiter?.email || '' },
              {
                text: recruiter?.personalInformation?.contact?.ext
                  ? `Ext: ${recruiter?.personalInformation?.contact?.ext}`
                  : null
              }
            ],
            actionsProps: {
              data: recruiter,
              namespace: EntityType.Roster,
              actions: [
                {
                  name: RowActions.Call,
                  value: 'phone',
                  darkMode: true
                },
                {
                  name: RowActions.Text,
                  value: 'phone',
                  darkMode: true
                },
                {
                  name: RowActions.Email,
                  value: 'email',
                  darkMode: true
                }
              ],
              contactValues: {
                phone: recruiter?.personalInformation?.contact?.phone,
                email: recruiter?.personalInformation?.contact?.email
              }
            }
          }}
          initials={recruiter?.initials}
          showAvatarLabel
          showDetails
          showInfoCard
          title={recruiter?.personalInformation?.full_name}
        />
        {isReassignButtonVisible && (
          <FPIconButton
            icon={SvgAdd}
            size="sm"
            className={classes.addButton}
            onClick={onReassignClick}
          />
        )}

        {assistant && (
          <ProfileAvatar
            detail="Collaborator"
            email={assistant?.recruiter?.email}
            infoCardProps={{
              type: 'Collaborator',
              details: [
                {
                  text: assistant?.coach?.full_name ? `Team: ${assistant?.coach?.full_name}` : null
                },
                { text: assistant?.recruiter?.email || '' },
                {
                  text: assistant?.recruiter?.personalInformation?.contact?.ext
                    ? `Ext: ${assistant?.recruiter?.personalInformation?.contact?.ext}`
                    : null
                }
              ],
              actionsProps: {
                data: assistant?.recruiter,
                namespace: EntityType.Roster,
                actions: [
                  {
                    name: RowActions.Call,
                    value: 'phone',
                    darkMode: true
                  },
                  {
                    name: RowActions.Text,
                    value: 'phone',
                    darkMode: true
                  },
                  {
                    name: RowActions.Email,
                    value: 'email',
                    darkMode: true
                  }
                ],
                contactValues: {
                  phone: assistant?.recruiter?.personalInformation?.contact?.phone,
                  email: assistant?.recruiter?.personalInformation?.contact?.email
                }
              }
            }}
            initials={assistant?.recruiter?.initials}
            showAvatarLabel
            showInfoCard
            showDetails
            title={assistant?.recruiter?.personalInformation?.full_name}
          />
        )}
      </div>
    </When>
  );
};

type ProfileRecruitersViewProps = {
  loading: boolean,
  recruiter: Object,
  assistant: Object,
  coach: Object,
  onReassignClick: () => {},
  isReassignButtonVisible: boolean
};

const ProfileRecruitersView = ({
  loading,
  assistant,
  recruiter,
  coach,
  onReassignClick,
  isReassignButtonVisible
}: ProfileRecruitersViewProps) => {
  const classes = useStyles();
  const [uiState, setUiState] = useState({
    isRecruiterView: false
  });

  const toogleCardView = () =>
    setUiState(prev => ({
      ...prev,
      isRecruiterView: !uiState.isRecruiterView
    }));

  return (
    <div className={classes.recruiterAvatarsWrapper}>
      <RecruiterDetailsCard
        onReassignClick={onReassignClick}
        assistant={assistant}
        coach={coach}
        isReassignButtonVisible={isReassignButtonVisible}
        loading={loading}
        onMouseEnter={toogleCardView}
        onMouseLeave={toogleCardView}
        recruiter={recruiter}
        uiState={uiState.isRecruiterView}
      />
    </div>
  );
};

ProfileRecruitersView.defaultProps = {
  isReassignButtonVisible: false
};

export default ProfileRecruitersView;
