// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { confirm, showAlert } from 'actions/app';
import { buildMultiplePhonesActionsRenderer } from 'hooks/datatable';
import { useProfileQuickView } from 'hooks/useProfileQuickView';
import API from 'services/API';
import strings from 'strings';
import type { ProfileDataTableProps } from 'types/app';
import { When } from 'UI/components/atoms/When';
import HiringAuthorityDrawer from 'UI/components/organisms/HiringAuthorityDrawer';
import ProfileTableLayout from 'UI/components/organisms/inventoryProfiles/ProfileTableLayout';
import { RowActions } from 'UI/constants/defaults';
import { componentDimensions } from 'UI/constants/dimensions';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType, TabKeys } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { SvgNoHiringAuthority } from 'UI/res/icons/milano';
import { getErrorMessage } from 'UI/utils';
import { updateUiState } from 'UI/utils/uiHelpers';

import FPQuickView from '../FPQuickView';

import { hiringAuthorityColumns } from './columns';

type HiringAuthorityTabProps = {
  DataTableProps: ProfileDataTableProps,
  endpoint: string,
  isCompanyProfile: boolean,
  profile: Object
};

const { emptyState } = componentDimensions;

const HiringAuthorityTab = ({
  DataTableProps,
  isCompanyProfile,
  profile,
  ...rest
}: HiringAuthorityTabProps) => {
  const statusCode = isCompanyProfile ? 200 : 204;
  const dispatch = useDispatch();

  const [uiState, setUiState] = useState({
    isHAOpen: false,
    isHAEditingOpen: false,
    selectedHA: null,
    shouldRefreshTableData: false
  });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *    A C T I O N S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const handleHiringAuthorityCompleted = async newHiringAuthority => {
    await updateUiState({ shouldRefreshTableData: true }, setUiState);
    handleHiringAuthorityClosed();

    if (!newHiringAuthority) {
      dispatch(
        showAlert({
          severity: 'error',
          title: `Error ${newHiringAuthority.code}`,
          body: getErrorMessage(newHiringAuthority.message)
        })
      );
    }
  };

  const addNewHiringAuthorityClick = () => {
    updateUiState(
      {
        isHAOpen: true,
        isHAEditingOpen: false
      },
      setUiState
    );
  };

  const handleHiringAuthorityClosed = () =>
    updateUiState({ isHAOpen: false, selectedHA: null, shouldRefreshTableData: false }, setUiState);

  const handleEditHAClick = (item: any) =>
    updateUiState({ selectedHA: item, isHAOpen: true, isHAEditingOpen: true }, setUiState);

  const handleDeleteHAClick = (item: any, getData) =>
    dispatch(
      confirm({
        severity: 'warning',
        title: strings.inventoryProfiles.sections.tabs.hiringAuthorities.dialogTitle,
        message: strings.formatString(
          strings.inventoryProfiles.sections.tabs.hiringAuthorities.dialogDescription,
          { hiringAuthority: item?.full_name?.full_name, company: profile?.name }
        ),
        cancelButtonText: strings.shared.ui.keepRemoveButtonsCopies.cancelButtonText,
        confirmButtonText: strings.shared.ui.keepRemoveButtonsCopies.confirmButtonText,
        onConfirm: async ok => {
          if (!ok) return;
          try {
            const response = await API.delete(
              `${isCompanyProfile ? Endpoints.Companies : Endpoints.JobOrders}/${profile?.id}/${
                Endpoints.HiringAuthorities
              }/${item.id}`
            );
            if (response.status === statusCode) {
              getData();
              dispatch(
                showAlert({
                  severity: 'success',
                  title: strings.inventoryProfiles.sections.tabs.hiringAuthorities.delete.title
                })
              );
            }
          } catch (error) {
            dispatch(
              showAlert({
                severity: 'error',
                title: 'Error',
                body: getErrorMessage(error)
              })
            );
          }
        }
      })
    );

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   T A B L E   A N D   D R A W E R   C O N F I G
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const sharedConfig = {
    onHACompleted: handleHiringAuthorityCompleted,
    onHAClosed: handleHiringAuthorityClosed,
    isEditing: uiState.isHAEditingOpen,
    hiringAuthority: uiState.selectedHA
  };

  const getHAEndpoint = companyID =>
    `${Endpoints.Companies}/${companyID}/${Endpoints.HiringAuthorities}/${
      uiState?.selectedHA?.id || ''
    }`;

  const hiringAuthorityDrawerConfig = {
    companyDrawer: {
      ...sharedConfig,
      type: EntityType.Company,
      companyId: profile?.id,
      endpoint: uiState.isHAEditingOpen
        ? getHAEndpoint(profile?.id)
        : `${Endpoints.Companies}/${profile?.id}/${Endpoints.HiringAuthorities}`
    },
    jobOrderDrawer: {
      ...sharedConfig,
      joborderId: profile?.id,
      type: EntityType.Joborder,
      companyId: profile?.company?.id || 0,
      endpoint: uiState.isHAEditingOpen
        ? getHAEndpoint(profile?.company?.id)
        : `${Endpoints.JobOrders}/${profile?.id}/${Endpoints.HiringAuthorities}`
    }
  };

  const { companyDrawer, jobOrderDrawer } = hiringAuthorityDrawerConfig;

  const finalActions = [
    {
      name: RowActions.Call,
      value: 'phone'
    },
    {
      name: RowActions.Text,
      value: 'phone'
    },
    {
      name: RowActions.Email,
      value: 'email'
    },
    { name: RowActions.Edit, callback: handleEditHAClick },
    { name: RowActions.Delete, callback: handleDeleteHAClick }
  ];

  const hiringAuthorityProps = isCompanyProfile ? { ...companyDrawer } : { ...jobOrderDrawer };

  const getSpecificTabColumns = handleAction => [
    buildMultiplePhonesActionsRenderer({
      customName: 'contactValues',
      handleAction,
      featureFlagToCheck: FeatureFlags.HiringAuthoritiesMultiplePhones
    })
  ];

  const {
    handleQuickViewPreview,
    itemsToNavigate,
    quickViewEntity,
    entityId,
    quickViewState,
    toggleQuickViewPreview
  } = useProfileQuickView({ quickViewEntityRoleProps: null });

  const handleRefreshTab = async () => {
    await updateUiState({ shouldRefreshTableData: true }, setUiState);
  };

  const handleTableDataLoaded = () => async () => {
    await updateUiState({ shouldRefreshTableData: false }, setUiState);
  };

  return (
    <>
      <ProfileTableLayout
        actionText={strings.inventoryProfiles.sections.tabs.hiringAuthorities.additionButton}
        customEmptyState={<SvgNoHiringAuthority size={emptyState.width} />}
        DataTableProps={{
          onFetchedData: uiState.shouldRefreshTableData && handleTableDataLoaded,
          ...DataTableProps
        }}
        enableQuickView
        finalActions={finalActions}
        hasProfileLoaded={profile?.id}
        initialColumns={hiringAuthorityColumns}
        customColumnsExtender={getSpecificTabColumns}
        onNewItemClick={addNewHiringAuthorityClick}
        profileModule={EntityType.HiringAuthority}
        shouldRefresh={uiState.shouldRefreshTableData}
        tabKey={TabKeys.HiringAuthorities}
        onQuickViewClick={handleQuickViewPreview}
        {...rest}
      />

      <When condition={entityId && quickViewState.isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: quickViewState.isQuickViewOpen
          }}
          onClose={toggleQuickViewPreview}
          entityType={quickViewEntity}
          id={entityId}
          itemsToNavigate={itemsToNavigate}
          onEditionCompleted={handleRefreshTab}
        />
      </When>

      {uiState.isHAOpen && (
        <HiringAuthorityDrawer drawerProps={{ open: true }} {...hiringAuthorityProps} />
      )}
    </>
  );
};

HiringAuthorityTab.defaultProps = {
  isCompanyProfile: false
};

export default HiringAuthorityTab;
