import React from 'react';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';

const { healthDistributionBehind } = strings.commandCenter.drawers;

export const HealthDistributionBehind = () => {
  return <div>HealthDistributionBehind</div>;
};

export const HealthDistributionBehindConfig = {
  title: healthDistributionBehind.title,
  subHeader: (
    <>
      <Typography
        dangerouslySetInnerHTML={{
          __html: healthDistributionBehind.subTitle.firstPart.replace(
            '{healthStatus}',
            '<strong>Behind</strong>'
          )
        }}
      />
      <Typography
        dangerouslySetInnerHTML={{
          __html: healthDistributionBehind.subTitle.secondPart.replace(
            '{healthStatus}',
            '<strong>Behind:</strong>'
          )
        }}
      />
    </>
  ),
  component: HealthDistributionBehind
};
