// @flow
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { showAlert } from 'actions/app';
import { EntityRoutes } from 'routes/constants';
import {
  CreatedFromVariants,
  createSearchProject,
  ParamsTypes,
  updateSearchProject
} from 'services/searchProjects';
import { getAllSearchProjectsFolders } from 'services/searchProjects/Folders';
import strings from 'strings';
import type { SearchProjectFormDrawerPropTypes } from 'types/app';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import TreeViewSelector from 'UI/components/molecules/TreeViewSelector/TreeViewSelector';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import {
  DEFAULT_SEARCH_PROJECT_FOLDERS_EXCLUDE_IDS,
  SearchProjectPrivacyScopes
} from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';

const { searchProject: formCopies } = strings.searchProjects.forms;
const radioOptions = [SearchProjectPrivacyScopes.private, SearchProjectPrivacyScopes.public];

const getPrivacyScope = scope => SearchProjectPrivacyScopes[scope];
const FORM_FIELDS_MAP = {
  name: {
    key: 'projectName'
  },
  parentFolder: {
    key: 'parentFolder'
  },
  privacyScope: {
    key: 'scope'
  }
};

const SearchProjectFormDrawer = ({
  bulkId = null,
  createdFrom = null,
  isSearchProjectBeingEdited = false,
  itemsToAdd = null,
  moveCopiedItems = false,
  onClose,
  onSubmitSearchProject = null,
  queryParams = null,
  searchProject = null
}: SearchProjectFormDrawerPropTypes) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isFoldersFeatureActive = hasFeatureFlag(FeatureFlags.SearchProjectsFolders);

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false
  });

  const form = useForm({
    defaultValues:
      searchProject && typeof searchProject === 'object'
        ? {
            [FORM_FIELDS_MAP.name.key]: searchProject.name,
            [FORM_FIELDS_MAP.privacyScope.key]: SearchProjectPrivacyScopes.getPrivacyState(
              searchProject.isPrivate
            ).id
          }
        : {
            [FORM_FIELDS_MAP.privacyScope.key]: SearchProjectPrivacyScopes.private.id
          }
  });
  const { handleSubmit, register, setValue, watch, errors } = form;
  const scopeWatcher = watch(FORM_FIELDS_MAP.privacyScope.key);

  useEffect(() => {
    register({ name: FORM_FIELDS_MAP.privacyScope.key });
  }, [register]);

  const handleRadioChange = event => setValue(FORM_FIELDS_MAP.privacyScope.key, event.target.value);
  const paramsType =
    createdFrom === CreatedFromVariants.BulkMetrics
      ? ParamsTypes.MetricsParams
      : ParamsTypes.SearchParams;

  const onSubmit = handleSubmit(async formData => {
    setUiState({ isSaving: true, isSuccess: false });
    const mappedData = {
      name: formData[FORM_FIELDS_MAP.name.key].trim(),
      isPrivate: getPrivacyScope(formData[FORM_FIELDS_MAP.privacyScope.key]).value
    };
    const parentFolder = formData[FORM_FIELDS_MAP.parentFolder.key];

    if (isFoldersFeatureActive) {
      mappedData.folderId = parentFolder.id;
    }

    const { alert, data, success } = await (isSearchProjectBeingEdited
      ? updateSearchProject({ ...mappedData, id: searchProject?.id })
      : createSearchProject({
          ...mappedData,
          itemsToAdd,
          queryParams,
          searchProjectId: searchProject?.id,
          moveCopiedItems,
          paramsType,
          bulkId
        }));

    dispatch(showAlert(alert));

    if (success) {
      const newData = isSearchProjectBeingEdited ? data : null;
      if (isFoldersFeatureActive && newData) {
        newData.folder = { id: parentFolder.id, name: parentFolder.name };
      }
      onSubmitSearchProject && onSubmitSearchProject(success, newData);
      !isSearchProjectBeingEdited &&
        setTimeout(() => {
          history.push(EntityRoutes.SearchProjectPreview.replace(':id', data.id), {
            createdFrom: createdFrom || 'default'
          });
        }, 1);
    }

    setUiState({ isSaving: false, isSuccess: success });
  });

  return (
    <DrawerContentLayout
      title={isSearchProjectBeingEdited ? formCopies.titles.edit : formCopies.titles.create}
      drawerProps={{ open: true }}
      onClose={onClose}
      onSubmit={onSubmit}
      uiState={uiState}
    >
      <FormContext {...form}>
        <NumberedForm>
          <NumberedForm.Item title={formCopies.sections.titles.details}>
            <TextBox
              label={formCopies.sections.name.label}
              name={FORM_FIELDS_MAP.name.key}
              inputRef={register({
                required: formCopies.sections.name.requiredMessage,
                minLength: {
                  value: 5,
                  message: formCopies.sections.name.minLengthMessage
                }
              })}
              error={!!errors?.projectName}
              errorText={errors?.projectName?.message}
            />
            <FPRadioGroup
              options={radioOptions}
              onChange={handleRadioChange}
              value={scopeWatcher}
              adviceText={formCopies.sections.visibility[getPrivacyScope(scopeWatcher).id]}
            />
          </NumberedForm.Item>
          <When condition={isFoldersFeatureActive}>
            <NumberedForm.Item title={formCopies.sections.titles.parentFolder}>
              <TreeViewSelector
                defaultValue={searchProject?.folder || null}
                formFieldName={FORM_FIELDS_MAP.parentFolder.key}
                placeholder={formCopies.sections.parentFolder.placeholder}
                shouldRegisterWithin
                requiredMessage={formCopies.sections.parentFolder.requiredMessage}
                TreeViewLoaderProps={{
                  enableIconClick: true,
                  onlyShowFolders: true,
                  service: {
                    method: getAllSearchProjectsFolders,
                    queryParams: { excludeIds: DEFAULT_SEARCH_PROJECT_FOLDERS_EXCLUDE_IDS }
                  }
                }}
              />
            </NumberedForm.Item>
          </When>
        </NumberedForm>
      </FormContext>
    </DrawerContentLayout>
  );
};

export default SearchProjectFormDrawer;
