import isNil from 'lodash/isNil';
import moment from 'moment';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import { currencyFormatter, formatPercentage } from 'UI/utils';

import { COMMAND_CENTER_BASE_URL, ENDPOINTS, METRICS } from '../constants';

import { createQueryParams } from './utils';

export const getFinanceSummary = async (params = {}) => {
  const queryParams = createQueryParams(params);

  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.financeSummary}?${queryParams}`
  ).then(({ data }) => data);
};

export const getFinanceRecruiterSummary = async ({ recruiter }) => {
  const defaultParams = {
    metrics: 'drawBalance,trackingProduction,production,cashIn,estimate,outstandingBillings',
    recruiter
  };
  const queryParams = createQueryParams(defaultParams);

  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.financeRecruiterSummary}?${queryParams}`
  ).then(({ data }) => {
    const keyOrder = [
      'drawBalance',
      'trackingProduction',
      'production',
      'cashIn',
      'estimate',
      'outstandingBillings'
    ];

    const orderedData = keyOrder.reduce((acc, key) => {
      if (!isNil(data[key])) {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    return Object.keys(orderedData).map(key => {
      const value = currencyFormatter(orderedData[key]);
      return {
        title: strings.commandCenter.metricsTitles[key],
        value
      };
    });
  });
};

export const getFinancialProductivityStatsSummary = async ({ metrics } = {}) => {
  const defaultMetrics = ['production', 'cashIn', 'perDeskAverage', 'adjustedAverage'];

  const defaultParams = {
    metrics: metrics ? metrics.join(',') : defaultMetrics.join(',')
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.financeSummary}?${queryParams}`
  ).then(({ data }) => {
    const [stats] = data;
    return Object.keys(stats).map(key => ({
      title: strings.commandCenter.metricsTitles[key],
      value:
        key !== 'adjustedAverage' ? currencyFormatter(stats[key]) : formatPercentage(stats[key], 1)
    }));
  });
};

export const getFinanceSummaryByTeamByRegionalDirector = async ({
  startDate,
  endDate,
  metrics
} = {}) => {
  const defaultMetrics = 'production,cashIn,perDeskAverage,adjustedAverage';

  const finalMetrics = metrics ? metrics.join(',') : defaultMetrics;

  const defaultParams = {
    startDate,
    endDate,
    metrics: finalMetrics
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.financeSummary}?groupBy=team&${queryParams}`
  );
};

export const getProductionAndCashInByMonth = async ({ startDate, endDate, groupBy } = {}) => {
  const defaultParams = {
    startDate: startDate ?? moment(new Date()).format('YYYY-01-01T00:00:00'),
    endDate: endDate ?? moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
    metrics: 'production,cashIn',
    groupBy: groupBy ?? 'month'
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.financeSummaryHistory}?${queryParams}`
  )
    .then(({ data }) => data.sort((a, b) => new Date(a.date) - new Date(b.date)))
    .then(data =>
      data.map(({ date, production, cashIn }) => ({
        month: new Date(date).toLocaleString('default', { month: 'long' }),
        production,
        cashIn
      }))
    );
};

export const getFinanceHealthDistribution = async ({
  groupBy,
  startDate,
  endDate,
  team,
  metrics
} = {}) => {
  const defaultMetrics = Object.values(METRICS)
    .map(({ paramName }) => paramName)
    .join(',');

  const finalMetrics = metrics ? metrics.join(',') : defaultMetrics;
  const defaultParams = {
    groupBy,
    startDate,
    endDate,
    metrics: finalMetrics,
    team
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.financeHealthSummary}?${queryParams}`
  );
};

export const getFinanceHealthSummaryByRegionalDirector = async ({
  startDate,
  endDate,
  metrics
} = {}) => {
  const defaultMetrics = Object.values(METRICS)
    .map(({ paramName }) => paramName)
    .join(',');

  const finalMetrics = metrics ? metrics.join(',') : defaultMetrics;

  const defaultParams = {
    startDate,
    endDate,
    metrics: finalMetrics,
    orderBy: 'teamName'
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.financeHealthSummary}?groupBy=team&${queryParams}`
  );
};

export const getFinanceSummaryByTeam = async ({ startDate, endDate, metrics } = {}) => {
  const defaultMetrics = 'production,cashIn';

  const finalMetrics = metrics ? metrics.join(',') : defaultMetrics;

  const defaultParams = {
    startDate: startDate ?? moment(new Date()).format('YYYY-MM-01T00:00:00'),
    endDate: endDate ?? moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
    metrics: finalMetrics
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.financeSummary}?groupBy=team&${queryParams}`
  );
};

export const getLowHealthMembersByTeam = async ({ startDate, endDate } = {}) => {
  const defaultParams = {
    startDate,
    endDate,
    metrics: 'atRisk,behind',
    orderBy: 'atRisk',
    direction: 'desc',
    groupBy: 'team'
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.financeHealthSummary}?${queryParams}`
  ).then(({ data }) => data);
};
