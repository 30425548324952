import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { getProductivityTeam } from 'features/command-center/services';
import usePageTitle from 'hooks/usePageTitle';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { AppName } from 'UI/constants/defaults';
import { SvgNoCandidates } from 'UI/res';

import { LayoutSkeleton } from '../../shared';
import { Layout } from '../Layout';

import { Breadcrumb, Path } from './TeamLayout.styled';

const getTeamName = fullName => {
  if (!fullName) return strings.commandCenter.teamPage.title;
  const [teamName] = fullName.split(' ');
  return `${strings.commandCenter.teamPage.title} ${teamName}`;
};

export const TeamLayout = ({ children, onRefresh, teamId }) => {
  const [teamLeaderData, setTeamLeaderData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  usePageTitle({
    title: `${AppName} | ${getTeamName(teamLeaderData?.managerName)}`,
    isLoading
  });

  const getData = useCallback(async () => {
    const requestParams = { teams: teamId };
    try {
      setIsLoading(true);
      const response = await getProductivityTeam(requestParams);
      setTeamLeaderData(response);
    } finally {
      setIsLoading(false);
    }
  }, [teamId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const renderEmptyState = (
    <EmptyPlaceholder
      subtitle={strings.commandCenter.activityPage.notFound.subtitle}
      title={strings.commandCenter.activityPage.notFound.title}
      customEmptyState={<SvgNoCandidates height={400} />}
    />
  );

  return (
    <Layout
      isBackNavigationEnabled
      isLoading={isLoading}
      title={teamLeaderData && getTeamName(teamLeaderData?.managerName)}
      path={
        teamLeaderData && (
          <Path>
            {strings.commandCenter.title} /{' '}
            <Breadcrumb component={Link} to={EntityRoutes.CommandCenterOverview}>
              {strings.commandCenter.teamPage.path}
            </Breadcrumb>
          </Path>
        )
      }
      onRefresh={teamLeaderData && onRefresh}
    >
      {isLoading ? (
        <LayoutSkeleton />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {teamLeaderData ? children : renderEmptyState}
          </Grid>
        </Grid>
      )}
    </Layout>
  );
};
