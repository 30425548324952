import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import { Widget } from 'features/command-center';
import { getActivityHistory } from 'features/command-center/services';
import moment from 'moment';
import strings from 'strings';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { DateFormats } from 'UI/constants/defaults';
import { createCustomStaticRanges, getYearToDate } from 'UI/utils';

import { DateRange } from '../../shared/InlineFilters/components/DateRange';

import { DEFAULT_OPTIONS, formatResponse } from './GoalsProgressInTime.utils';

const STRINGS = strings.commandCenter.activity.goalsProgressInTime;
const defaultRange = getYearToDate();

export const GoalsProgressInTime = ({ requestParams = {} }) => {
  const [dateRange, setDateRange] = useState(defaultRange);
  const queryDateRange = {
    startDate: moment(dateRange.startDate).format(DateFormats.QueryShortFormat),
    endDate: moment(dateRange.endDate).format(DateFormats.QueryShortFormat)
  };

  const handleDateRangeChange = selectedDateRange => setDateRange(selectedDateRange);

  return (
    <Widget
      header={STRINGS.title}
      promises={[
        () =>
          getActivityHistory({
            groupBy: 'month',
            metrics: 'inMails,posts,bulks,peopleReached,calls',
            resultType: 'percentage',
            ...queryDateRange,
            ...requestParams
          })
      ]}
    >
      {([response]) => {
        const data = formatResponse(response);

        return (
          <>
            <DateRange
              DateRangerPickerProps={{
                staticRanges: createCustomStaticRanges(additionalPeriodRanges)
              }}
              onPeriodChange={handleDateRangeChange}
              defaultRange={dateRange}
            />

            <Chart
              chartType="LineChart"
              width="100%"
              height="400px"
              data={data}
              options={DEFAULT_OPTIONS}
            />
          </>
        );
      }}
    </Widget>
  );
};
