import isNil from 'lodash/isNil';

const getRandomPastelColor = () => {
  const r = Math.floor(Math.random() * 128 + 127); // between 127 and 255
  const g = Math.floor(Math.random() * 128 + 127);
  const b = Math.floor(Math.random() * 128 + 127);

  const toHex = value => value.toString(16).padStart(2, '0');

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

const validateResponse = response => {
  return response.every(item => {
    const requiredFields = ['state_slug', 'state_title', 'latitude', 'longitude', 'country_slug'];
    const areRequiredFieldsValid = requiredFields.every(field => !isNil(item[field]));

    return areRequiredFieldsValid;
  });
};

export const formatResponse = response => {
  const formattedResponse = {
    tree: [],
    countries: [],
    markers: []
  };
  const isValidResponse = validateResponse(response);

  if (!response || !isValidResponse) return formattedResponse;

  const industryMap = new Map();
  const countryMap = new Map();
  const markerMap = new Map();
  const colorMap = new Map();

  response.forEach(item => {
    const {
      country_slug: countrySlug,
      industry_id: industryId,
      industry_title: industryTitle,
      latitude,
      longitude,
      specialty_id: specialtyId,
      specialty_title: specialtyTitle,
      subspecialty_id: subspecialtyId,
      subspecialty_title: subspecialtyTitle,
      state_slug: stateSlug,
      state_title: stateTitle
    } = item;

    // Process industries
    if (!industryMap.has(industryId)) {
      industryMap.set(industryId, {
        id: `${industryId}`,
        name: industryTitle,
        children: []
      });
    }
    const industry = industryMap.get(industryId);

    if (specialtyId) {
      const specialtyKey = `${industryId}-${specialtyId}`;
      let specialty = industry.children.find(indSpecialty => indSpecialty.id === specialtyKey);

      if (!specialty) {
        specialty = {
          id: specialtyKey,
          name: specialtyTitle,
          children: []
        };
        industry.children.push(specialty);
      }

      if (subspecialtyId) {
        const subspecialtyKey = `${specialtyKey}-${subspecialtyId}`;
        const subspecialtyExists = specialty.children.some(child => child.id === subspecialtyKey);

        if (!subspecialtyExists) {
          specialty.children.push({
            id: subspecialtyKey,
            name: subspecialtyTitle,
            children: []
          });
        }
      }
    }

    // Assign a color to the industry if it doesn't have one
    if (!colorMap.has(industryId)) {
      colorMap.set(industryId, getRandomPastelColor());
    }

    // Process countries and states
    if (!countryMap.has(countrySlug)) {
      countryMap.set(countrySlug, {
        name: countrySlug || '',
        states: new Set()
      });
    }
    const country = countryMap.get(countrySlug);
    country.states.add(stateTitle);

    // Process markers
    const locationKey = `${latitude}-${longitude}-${stateSlug}`;
    if (!markerMap.has(locationKey)) {
      markerMap.set(locationKey, {
        latitude,
        longitude,
        state: stateSlug,
        values: new Map()
      });
    }

    const location = markerMap.get(locationKey);
    if (!location.values.has(industryId)) {
      location.values.set(industryId, {
        id: industryId,
        latitude,
        longitude,
        state: stateTitle,
        name: industryTitle,
        color: colorMap.get(industryId), // Use the assigned color
        specialties: new Set()
      });
    }

    const industryInMarker = location.values.get(industryId);
    if (specialtyTitle) {
      industryInMarker.specialties.add(specialtyTitle);
    }
  });

  formattedResponse.tree = Array.from(industryMap.values());
  formattedResponse.countries = Array.from(countryMap.values()).map(country => ({
    ...country,
    states: Array.from(country.states)
  }));
  formattedResponse.markers = Array.from(markerMap.values()).map(location => ({
    ...location,
    values: Array.from(location.values.values()).map(industry => ({
      ...industry,
      specialties: Array.from(industry.specialties)
    }))
  }));

  return formattedResponse;
};
