import React from 'react';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';

const { healthDistributionOnTrack } = strings.commandCenter.drawers;

export const HealthDistributionOnTrack = () => {
  return <div>HealthDistributionOnTrack</div>;
};

export const HealthDistributionOnTrackConfig = {
  title: 'On Track Recruiters',
  subHeader: (
    <>
      <Typography
        dangerouslySetInnerHTML={{
          __html: healthDistributionOnTrack.subTitle.firstPart.replace(
            '{healthStatus}',
            '<strong>On Track</strong>'
          )
        }}
      />
      <Typography
        dangerouslySetInnerHTML={{
          __html: healthDistributionOnTrack.subTitle.secondPart.replace(
            '{healthStatus}',
            '<strong>On Track:</strong>'
          )
        }}
      />
    </>
  ),
  component: HealthDistributionOnTrack
};
