import React from 'react';
import { useDrawerManager } from 'features/command-center/contexts';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';

import { DRAWERS_CONFIG } from './Drawers.constants';

export const Drawers = () => {
  const drawerManager = useDrawerManager();

  if (!Object.keys(DRAWERS_CONFIG).includes(drawerManager.drawerState.activeDrawer)) {
    return null;
  }

  const drawerConfig = DRAWERS_CONFIG[drawerManager.drawerState.activeDrawer];
  const Component = drawerConfig?.component;

  return (
    <DrawerContentLayout
      title={drawerConfig?.title}
      subheader={drawerConfig?.subHeader}
      drawerProps={{
        open: drawerManager.drawerState.isOpen
      }}
      onClose={drawerManager.closeDrawer}
    >
      <Component />
    </DrawerContentLayout>
  );
};
