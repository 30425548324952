import { COMMAND_CENTER_BASE_URL, ENDPOINTS } from 'features/command-center/constants';
import isNil from 'lodash/isNil';
import moment from 'moment';
import API, { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import { DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { CandidateEntity, JobOrderEntity } from 'UI/constants/entityTypes';
import { currencyFormatter, formatNumber, formatPercentage, formatRatio } from 'UI/utils';

import { createQueryParams } from './utils';

const dawnOfGpac = moment('1990-01-01').format(DateFormats.QueryFormat);
const currentDayPlusOne = moment().add(1, 'days').format(DateFormats.QueryFormat);

const VALID_CANDIDATE_STATUSES = ['Alpha', 'POEJO'];
const VALID_JOB_ORDER_STATUSES = ['Search Assignment', 'POEJO'];

const FORMATTERS = {
  avgFee: value =>
    currencyFormatter(value, 0, { notation: 'standard', forceCompactNotation: false }),
  fallOffRatePercent: value => formatPercentage(value)
};

const convertSecondsToHours = value => {
  if (!value) return 0;
  return value / 3600;
};

const convertToHours = value => {
  if (!value) return 0;
  return value / 60;
};

export const getActivitySummary = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activitySummary}?${queryParams}`
  ).then(({ data }) => data);
};

export const getActivityHistory = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activityHistory}?${queryParams}`
  ).then(({ data }) => data);
};

export const getWeekActivity = async ({ groupBy = 'team', recruiter, team }) => {
  const defaultParams = {
    groupBy,
    metrics: 'inMails,bulks,peopleReached,posts,calls,phoneTime',
    recruiter,
    team
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activityWeek}?${queryParams}`
  ).then(({ data }) => {
    const { goals, stats } = Object.keys(data).reduce(
      (acc, key) => {
        if (key.includes('Goal')) {
          acc.goals[key] = data[key];
        } else {
          acc.stats[key] = data[key];
        }
        return acc;
      },
      { goals: {}, stats: {} }
    );

    const finalData = Object.keys(stats).map(key => {
      const isKeyPhoneTime = key === 'phoneTime';

      const value = isKeyPhoneTime ? convertSecondsToHours(Number(stats[key])) : Number(stats[key]);
      const goal = isKeyPhoneTime ? convertToHours(goals[`${key}Goal`]) : goals[`${key}Goal`];

      return {
        color: value < goal ? 'purple' : 'black',
        title: strings.commandCenter.metricsTitles[key],
        value: formatRatio(value, goal, 'scalar', {
          defaultEmptyRatio: '0/0',
          notation: 'standard',
          forceCompactNotation: false,
          decimalPlaces: 1
        })
      };
    });

    return finalData;
  });
};

export const getYTDActivityPlacementsSummary = async ({ groupBy = 'team', recruiter, team }) => {
  const defaultParams = {
    groupBy,
    metrics: 'placements,fallOffs,placedRatio,avgFee',
    recruiter,
    team
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activityPlacementsSummary}?${queryParams}`
  ).then(({ data }) => {
    const keyOrder = [
      'totalPlacements',
      'placedRatio',
      'totalFallOffs',
      'fallOffRatePercent',
      'avgFee'
    ];

    const orderedData = keyOrder.reduce((acc, key) => {
      if (!isNil(data[key])) {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    return Object.keys(orderedData).map(key => {
      const value = FORMATTERS[key] ? FORMATTERS[key](orderedData[key]) : data[key];
      return {
        title: strings.commandCenter.metricsTitles[key],
        value
      };
    });
  });
};

export const getActivityPlacementsHistory = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activityPlacementsHistory}?${queryParams}`
  ).then(({ data }) => data);
};

export const getActivitySendoutsHistory = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activitySendoutsHistory}?${queryParams}`
  ).then(({ data }) => data);
};

export const getActivityWeekInventoryGrowth = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return API.get(`${Endpoints.Dashboard}/totalInventory?${queryParams}`).then(({ data }) =>
    data.map(item => ({ title: item.title, value: formatNumber(item.total) }))
  );
};

export const getAssignedCandidates = async (params = {}) => {
  const defaultParams = {
    ...params,
    identifier: 'types',
    entityType: CandidateEntity.searchParam,
    startDate: dawnOfGpac,
    endDate: currentDayPlusOne
  };
  const queryParams = createQueryParams(defaultParams);
  return API.get(`${Endpoints.Dashboard}/totalTypesOrStatus?${queryParams}`).then(({ data }) => {
    const validStatusesData = data.filter(item => VALID_CANDIDATE_STATUSES.includes(item[0]));
    const formattedData = validStatusesData.map(item => ({
      title: `CA ${item[0]}` ?? '',
      value: item[1] ?? 0
    }));

    return [
      {
        title: 'Total Candidates',
        value: formattedData.reduce((sum, item) => sum + item.value, 0)
      },
      ...formattedData
    ];
  });
};

export const getAssignedJobOrders = async (params = {}) => {
  const defaultParams = {
    ...params,
    identifier: 'types',
    entityType: JobOrderEntity.searchParam,
    startDate: dawnOfGpac,
    endDate: currentDayPlusOne
  };
  const queryParams = createQueryParams(defaultParams);
  return API.get(`${Endpoints.Dashboard}/totalTypesOrStatus?${queryParams}`).then(({ data }) => {
    const validStatusesData = data.filter(item => VALID_JOB_ORDER_STATUSES.includes(item[0]));
    const formattedData = validStatusesData.map(item => ({
      title: `JO ${item[0]}` ?? '',
      value: item[1] ?? 0
    }));

    return [
      {
        title: 'Total Job Orders',
        value: formattedData.reduce((sum, item) => sum + item.value, 0)
      },
      ...formattedData
    ];
  });
};

export const getActivityTrendsHistory = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activityTrendsHistory}?${queryParams}`
  ).then(({ data }) => data);
};

export const getPilById = async (params = {}) => {
  const { recruiter, ...rest } = params;
  const queryParams = createQueryParams(rest);
  return API.get(`${Endpoints.Digs}/by-employee-number/${recruiter}?${queryParams}`).then(
    ({ data }) => data
  );
};
