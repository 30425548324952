import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  OVERALL_PERFORMANCE_COLUMNS,
  OVERALL_PERFORMANCE_ORDER_BY_OPTIONS,
  SimplifiedTable
} from 'features/command-center/components/shared';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';

import { formatResponse } from './utils';

const { healthDistributionHeadcount } = strings.commandCenter.drawers;

export const HealthDistributionHeadcount = () => {
  return (
    <SimplifiedTable
      apiInstance={SECONDARY_API_CLIENT}
      apiVersion={2}
      columns={OVERALL_PERFORMANCE_COLUMNS}
      endpoint="command-center/overall-performance?direction=asc&end_date=2024-09-30 23:59:59&keyword=&metrics=recruiterName,team,healthStatus,daysInStatus&offset=10&orderBy=healthStatus&page=1&perPage=10&size=10&start_date=2024-09-01 00:00:00&status=behind&userFilter=0"
      formatResponse={formatResponse}
      header={strings.commandCenter.activity.overallPerformance.title}
      orderByOptions={OVERALL_PERFORMANCE_ORDER_BY_OPTIONS}
      showWrapper={false}
    />
  );
};

export const HealthDistributionHeadCountConfig = {
  title: healthDistributionHeadcount.title,
  subHeader: (
    <>
      <Typography>{healthDistributionHeadcount.subTitle}</Typography>
    </>
  ),
  component: HealthDistributionHeadcount
};
