export const INLINE_FILTERS_KEYS = Object.freeze({
  dominate: {
    hotInventory: 'dominate-hot-inventory',
    interviews: 'dominate-interviews',
    outstandingBillings: 'dominate-outstanding-billings'
  },
  activity: {
    overallPerformance: 'activity-overall-performance',
    overallPerformanceByTeam: 'activity-overall-performance-by-team'
  }
});
