import { THEME } from 'GlobalStyles';
import { formatNumber } from 'UI/utils';

import { createCustomTooltip } from '../../finance/finance.utils';

const FONT = THEME.typography.fontFamily;

export const DEFAULT_OPTIONS = {
  chartArea: { width: '80%' },
  hAxis: {
    title: '',
    titleTextStyle: { fontFamily: FONT, fontStyle: 'normal !important' }
  },
  vAxis: {
    format: 'percent'
  },

  fontName: FONT,
  animation: {
    startup: true,
    easing: 'inAndOut',
    duration: 1500
  },

  tooltip: { isHtml: true },
  legend: { position: 'top' },
  colors: [
    THEME.palette.common.black,
    THEME.palette.primary.main,
    THEME.palette.success.main,
    THEME.palette.warning.main,
    THEME.palette.error.main
  ],
  pointSize: 5
};

export const formatResponse = response => {
  if (!response || response.length === 0) {
    return [
      ['Month', 'InMails', 'Posts', 'Bulks', 'People Reached', 'Calls'],
      ['', 0, 0, 0, 0, 0]
    ];
  }

  const formattedData = [
    [
      'Month',
      'InMails',
      { role: 'tooltip', type: 'string', p: { html: true } },
      'Posts',
      { role: 'tooltip', type: 'string', p: { html: true } },
      'Bulks',
      { role: 'tooltip', type: 'string', p: { html: true } },
      'People Reached',
      { role: 'tooltip', type: 'string', p: { html: true } },
      'Calls',
      { role: 'tooltip', type: 'string', p: { html: true } }
    ],

    ...response.map(item => {
      const { month, year, inMails, posts, bulks, peopleReached, calls } = item;
      const monthYear = `${month} ${year}`;

      const normalizationFactor = 100;
      return [
        monthYear,
        inMails / normalizationFactor,
        createCustomTooltip({
          title: 'InMails',
          value: `${formatNumber(inMails, 0)}%`
        }),
        posts / normalizationFactor,
        createCustomTooltip({
          title: 'Posts',
          value: `${formatNumber(posts, 0)}%`
        }),
        bulks / normalizationFactor,
        createCustomTooltip({
          title: 'Bulks',
          value: `${formatNumber(bulks, 0)}%`
        }),
        peopleReached / normalizationFactor,
        createCustomTooltip({
          title: 'People Reached',
          value: `${formatNumber(peopleReached, 0)}%`
        }),
        calls / normalizationFactor,
        createCustomTooltip({
          title: 'Calls',
          value: `${formatNumber(calls, 0)}%`
        })
      ];
    })
  ];

  return formattedData;
};
