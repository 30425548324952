export const FeatureFlags = {
  BulkEmailArchiveTemplates: 'archiveBulkTemplates',
  BulkEmailArchiveTemplatesFolders: 'archiveTemplatesFolders',
  BulkEmailEditTemplatesFolders: 'editBulkTemplatesFolders',
  BulkEmailEnableSignatureOnSchedules: 'enableSignatureOnScheduleBulksPreview',
  BulkEmailRepliedEmailsStat: 'repliedEmailsBulkStats',
  BulkEmailStatsQuickView: 'bulkemail-quickview',
  BulkShowEmailVariant: 'bulkShowEmailVariant',
  BulkSmartTagOnEmailBody: 'smartTagOnEmailBody',
  BulkSmartTagOnSubject: 'smartTagOnSubject',
  BulkStatsTablesActions: 'bulkStatsTablesActions',
  BulkTemplatesRestoreSelectingFolder: 'restoreTemplatesSelectingFolder',
  CandidateDataSheetFlow: 'candidateDataSheetFlow',
  CandidatesMultiplePhones: 'candidatesMultiplePhones',
  CandidatesTabMultiplePhones: 'candidatesTabMultiplePhones',
  CategoryFilesSendout: 'categoryFilesSendout',
  CategoryFilesSendoutDebrief: 'categoryFilesSendoutDebrief',
  ChangeLogCandidate: 'changeLogCandidate',
  ChangeLogCompany: 'changeLogCompany',
  ChangeLogContact: 'changeLogContact',
  ChangeLogHA: 'changeLogHA',
  ChangeLogJobOrder: 'changeLogJobOrder',
  CommandCenter: 'commandCenter',
  CommandCenterActivity: 'commandCenterActivity',
  CommandCenterDominateSheet: 'commandCenterDominateSheet',
  CommandCenterDrillDown: 'commandCenterDrillDown',
  CommandCenterOverview: 'commandCenterOverview',
  CommandCenterPDA: 'commandCenterPDA',
  CompaniesMultiplePhones: 'companiesMultiplePhones',
  CompaniesTargetPriority: 'companiesTargetPriority',
  Contacts: 'contacts',
  ContactsMultiplePhones: 'contactsMultiplePhones',
  ConvertedContacts: 'redirectConvertedContacts',
  ConvertLegacyPhones: 'convertLegacyPhones',
  DashboardFeeAgreements: 'dashboardFA',
  DashboardPhone: 'dashboardPhone',
  DashboardSendoutLeaders: 'dashboardsendoutleaders',
  DashboardSendoutMetrics: 'dashboardsendoutmetrics',
  DashboardSendouts: 'dashboardsendouts',
  DashboardToolsUsage: 'dashboardtoolsusage',
  DashboardToolsUsageReport: 'dashboardtoolsusagereport',
  DisableQuickViewSwitch: 'quickViewSwitch',
  Drafts: 'drafts',
  EmailValidationColumn: 'emailValidationColumn',
  EmailVerdictV2: 'emailVerdictV2',
  EmailWithValidationColumnCandidates: 'emailWithValidationColumnCandidates',
  EmailWithValidationColumnNames: 'emailWithValidationColumnNames',
  EmailWithValidationColumnSearchProjectPreviews: 'emailWithValidationColumnSearchProjectPreviews',
  EmployeesMultiplePhones: 'employeesMultiplePhones',
  EnableActionsTab: 'enableNotesActionsTab',
  EnableQuickViewNavigation: 'enableQuickViewNavigation',
  EnableRemoveInBulkTables: 'enableRemoveInBulkTables',
  ExpandableTables: 'expandableTables',
  ExternalFeeAgreement: 'externalFA',
  ExtractContacts: 'extractContacts',
  FeeAgreement: 'feeagreement',
  FeeAgreementsSplitPayments: 'fa-split-payments',
  FileManager: 'fileManager',
  FilterByFeeAgreementPaymentScheme: 'filterByFeeAgreementPaymentScheme',
  FilterByNumberOfContactsRange: 'filterByNumberOfContactsRange',
  HaFeeAgreementTab: 'haFeeAgreementTab',
  HaJobOrderTab: 'haJobOrderTab',
  HaPlacementsTab: 'haPlacementsTab',
  HiringAuthoritiesMultiplePhones: 'hiringAuthoritiesMultiplePhones',
  Home: 'newHome',
  HomeMultipleRegions: 'homeMultipleRegions',
  HomeToCommandCenter: 'homeToCommandCenter',
  IndustriesViewer: 'industriesViewer',
  JobOrderColumnsHAProfile: 'jobOrderColumnsHAProfile',
  JobOrderDataSheetFlow: 'jobOrderDataSheetFlow',
  KeywordSearch: 'keywordsearch',
  LinkCompanies: 'linkCompanies',
  LogRocket: 'logrocket',
  MapSubspecialtyFilter: 'map_subspecialty_filter',
  MenuNavBar: 'menunavbar',
  MultiplePhonesColumn: 'multiplePhonesColumn',
  MultiplePhonesWithCommunicationActions: 'multiplePhonesWithCommunicationActions',
  MutiplePhonesListingTags: 'mutiplePhonesListingTags',
  NewCandidateFromContact: 'newCandidateFromContact',
  NewHiringAuthorityTabs: 'newHiringAuthorityTabs',
  NoHireLetter: 'noHireLetter',
  Notifications: 'notifications',
  OptOutFilter: 'optOutFilter',
  OtherEmailValidationProfiles: 'otherEmailValidationProfiles',
  PersonalEmailVerdictFilter: 'personalEmailVerdictFilter',
  PilDescriptions: 'pilDescriptions',
  PlacementCollections: 'placement-collections',
  PlacementDeletePermanently: 'placementDeletePermanently',
  PlacementPaymentsFromNetsuite: 'placement-paymets-from-netsuite',
  PlacementRequestFeeOrGuaranteeChanges: 'placementRequestFeeOrGuaranteeChanges',
  PlacementWithAssignmentDataSheet: 'placement-data-sheet',
  PlacementWithEstimates: 'placement-estimates',
  PlacementWithReferences: 'placement-references',
  ProfileLayout: 'profileLayout',
  ProfileNavigationControls: 'profileNavigationControls',
  RcEventFilter: 'rcEventFilter',
  RecruiterProfile: 'recruiterProfile',
  RecruiterProfileNotes: 'recruiterProfileNotes',
  ResendEmailHiringAuthoritySendout: 'resendEmailHiringAuthoritySendout',
  SearchProjectPreviewMultiplePhones: 'searchProjectPreviewMultiplePhones',
  SearchProjectsArchive: 'archiveSearchProjects',
  SearchProjectsFolders: 'searchProjectsFolders',
  SearchProjectsRefactor: 'searchProjectsRefactor',
  SendBulkViaOutlook: 'sendBulkViaOutlook',
  SendoutDuplicates: 'sendoutDuplicates',
  Sendouts: 'sendouts',
  SendoutsChannelPartner: 'sendoutsChannelPartner',
  SendoutsInterviewDetailsActions: 'sendoutsInterviewDetailsActions',
  SigningBackupService: 'signingBackup',
  UrgencyVerification: 'urgencyVerification',
  WebsiteFilter: 'websiteFilter'
};
