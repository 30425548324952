// @flow
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import queryString from 'query-string';
import strings from 'strings';
import type { OperationResult } from 'types/app';
import { SelectionType } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { getErrorMessage } from 'UI/utils';

import API from '../API';
import { makeRequest } from '../utils';

import { fillIncludedItems } from './utils';

export const ParamsTypes = {
  MetricsParams: 'bulk_metrics_params',
  SearchParams: 'search_params'
};

export const CreatedFromVariants = {
  BulkMetrics: 'bulkMetrics'
};

const { SearchProjects, SearchProjectsAddPreview, SearchProjectsInventory, SearchProjectDetail } =
  Endpoints;

const {
  forms: { searchProject: searchProjectFormCopies },
  archiveDialog,
  restoreDialog
} = strings.searchProjects;

/**
 * Service that allows to get search project main data (not items list)
 * @param {number} searchProjectId
 * @returns {Promise<OperationResult>}
 */
export const getSearchProjectQuickInfo = async searchProjectId => {
  const { data, ...restResponse } = await makeRequest({
    endpointOptions: {
      path: Endpoints.SearchProjectQuickInfo,
      replaceId: searchProjectId
    },
    method: 'get'
  });

  const {
    archived_at,
    bulkable_items,
    collaborators: collabs,
    created_at,
    daysForDeletion,
    folder,
    is_archived,
    is_mine,
    is_private,
    name,
    role_title,
    sent_date,
    total_items,
    total_companies
  } = data;

  const mappedData = {
    archivedAt: archived_at,
    bulkableItems: bulkable_items,
    collabs,
    createdAt: created_at,
    daysBeforeDeletion: daysForDeletion,
    folder: { id: folder?.id, name: folder?.name },
    isMine: is_mine,
    isPrivate: is_private,
    name,
    roleTitle: role_title,
    sentDate: sent_date,
    totalItems: total_items,
    totalCompanies: total_companies,
    isArchived: is_archived
  };

  return { ...restResponse, data: mappedData };
};

/**
 * Service that restores an archived search project
 * @param {number} searchProjectId
 * @returns {Promise<OperationResult>}
 */
export const restoreSearchProject = searchProjectId =>
  makeRequest({
    endpointOptions: {
      path: Endpoints.SearchProjectRestore,
      replaceId: searchProjectId
    },
    method: 'post',
    alertConfig: {
      success: {
        title: restoreDialog.alerts.success.title,
        body: ({ name }) => name
      },
      error: {
        title: restoreDialog.alerts.error.title
      }
    }
  });

/**
 * @param {Object} requestParams
 * @param {string} requestParams.name
 * @param {boolean} requestParams.isPrivate
 * @param {Object} requestParams.itemsToAdd
 * @param {Object} requestParams.queryParams
 * @param {number} [requestParams.searchProjectId]
 * @param {boolean} requestParams.moveCopiedItems
 * @param {string} requestParams.paramsType
 * @returns {Promise}
 */
export const createSearchProject = async ({
  bulkId,
  folderId,
  isPrivate,
  itemsToAdd,
  moveCopiedItems,
  name,
  paramsType,
  queryParams,
  searchProjectId
}): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    let requestData = {
      is_private: isPrivate,
      name
    };

    if (folderId) {
      requestData.folderId = folderId;
    }

    const createFromAnotherSP = !!searchProjectId;
    if (itemsToAdd.type === SelectionType.Include) {
      requestData = { ...requestData, ...fillIncludedItems(itemsToAdd.data) };
    } else if (itemsToAdd.type === SelectionType.Exclude) {
      if (paramsType === ParamsTypes.MetricsParams) requestData.email_history_id = bulkId;
      const commonExcludeDataParams = { filteredItems: itemsToAdd, queryParams };
      let excludeDataParams = {};
      if (createFromAnotherSP) {
        excludeDataParams = {
          dataName: paramsType,
          excludeMapperCallback: alternativeExcludeMapper
        };
      }
      requestData = {
        ...requestData,
        ...fillExcludeData({
          ...commonExcludeDataParams,
          ...excludeDataParams
        })
      };
    }
    if (createFromAnotherSP) requestData.search_project_id = searchProjectId;
    const dataToSend = moveCopiedItems
      ? { ...requestData, removeCopiedItems: moveCopiedItems }
      : requestData;
    const response = await API.post(SearchProjects, dataToSend);
    if (response.status === 201) {
      result.success = true;
      result.alert = {
        severity: 'success',
        title: 'Search Project Created Successfully',
        body: name
      };
      result.data = { id: response.data.id };
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: "Search Project wasn't created",
      body: getErrorMessage(error)
    };
  }
  return result;
};

/**
 * Update search project properties
 * @param {Object} SearchProject
 * @param {number} SearchProject.baseSearchProjectId
 * @param {string} SearchProject.name
 * @param {boolean} SearchProject.isPrivate
 * @param {boolean} SearchProject.addEmployees
 * @param {boolean} SearchProject.addHiringAuthorities
 * @param {boolean} SearchProject.excludeCompanies
 */

export const createSearchProjectsWithContactsFromCompanies = async ({
  baseSearchProjectId,
  name,
  isPrivate,
  excludeCompanies,
  addEmployees,
  addHiringAuthorities
}) => {
  const dataToSend = {
    name,
    is_private: isPrivate,
    search_params: {
      query: {
        direction: '',
        keyword: '',
        orderBy: '',
        page: '',
        perPage: 25,
        userFilter: '0'
      },
      exclude: []
    },
    search_project_id: baseSearchProjectId,
    excludeCompanies,
    addEmployees,
    addHiringAuthorities
  };

  const result = makeRequest({
    method: 'post',
    url: SearchProjects,
    data: dataToSend,
    alertConfig: {
      success: {
        title: 'Search Project Created',
        body: `Search project was created successfully`
      },
      error: {
        title: "Search Project wasn't created"
      }
    }
  });

  return result;
};

/**
 * Update search project properties
 *
 * @param {Object} SearchProject
 * @param {number} SearchProject.id
 * @param {string} SearchProject.name
 * @param {boolean} SearchProject.isPrivate
 */
export const updateSearchProject = ({ id, name, isPrivate, folderId }) =>
  makeRequest({
    method: 'put',
    url: SearchProjectDetail.replace(/:id/, id),
    data: { name, isPrivate, folderId },
    alertConfig: {
      success: {
        ...searchProjectFormCopies.alerts.success,
        body: name
      },
      error: searchProjectFormCopies.alerts.error
    }
  });

const formatArchiveAlert = (copy, action) => strings.formatString(copy, { action });

/**
 * Deletes a search project
 *
 * @param {number} id
 * @param {string} name
 */
export const archiveSearchProject = (id, name, isArchiveFeatureActive) =>
  makeRequest({
    method: 'delete',
    url: SearchProjectDetail.replace(/:id/, id),
    alertConfig: {
      success: {
        title: formatArchiveAlert(
          archiveDialog.alerts.success.title,
          isArchiveFeatureActive ? 'Archived' : 'Deleted'
        ),
        body: name
      },
      error: formatArchiveAlert(
        archiveDialog.alerts.error.title,
        isArchiveFeatureActive ? 'Archiving' : 'Deleting'
      )
    }
  });

export const addToSearchProject = async ({
  selectedSearchProjectId,
  moveCopiedItems,
  paramsType,
  bulkId,
  ...rest
}): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const requestData = getAddToSPRequestData(rest);
    const dataToSend = moveCopiedItems
      ? { ...requestData, removeCopiedItems: moveCopiedItems }
      : requestData;
    if (paramsType === ParamsTypes.MetricsParams) dataToSend.email_history_id = bulkId;
    const response = await API.put(
      SearchProjectsInventory.replace(':id', selectedSearchProjectId),
      dataToSend
    );
    if (response.status === 200) {
      result.success = true;
      result.alert = {
        severity: 'success',
        title: 'Items Added to Search Project Successfully'
      };
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: 'Items were not added to search project',
      body: getErrorMessage(error)
    };
  }
  return result;
};

/**
 * @param {Object} preview
 * @param {number} preview.currentSearchProjectId
 * @param {number} preview.selectedSearchProjectId
 * @param {Object} preview.itemsToAdd
 * @param {Object} preview.queryParams
 * @param {'searchProjectPreview' | 'other' | undefined} [preview.createdFrom]
 * @returns {Promise}
 */
export const getSearchProjectAddItemsPreview = async ({
  selectedSearchProjectId,
  paramsType,
  bulkId,
  ...rest
}): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const requestData = getAddToSPRequestData(rest);
    if (paramsType === ParamsTypes.MetricsParams) requestData.email_history_id = bulkId;
    const response = await API.put(
      SearchProjectsAddPreview.replace(':id', selectedSearchProjectId),
      requestData
    );
    if (response.status === 200) {
      result.data = { ...response.data };
      result.success = true;
    } else {
      result.alert = {
        severity: 'error',
        title: 'Items add preview',
        body: 'Unable to get row items preview to display repeated and new items'
      };
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: 'Job order detail',
      body: getErrorMessage(error)
    };
  }
  return result;
};

const getAddToSPRequestData = ({
  itemsToAdd,
  queryParams,
  createdFrom,
  currentSearchProjectId
}) => {
  const optionalParams = {};
  if (createdFrom === 'searchProjectPreview') {
    optionalParams.dataName = ParamsTypes.SearchParams;
    optionalParams.excludeMapperCallback = alternativeExcludeMapper;
  }
  if (createdFrom === CreatedFromVariants.BulkMetrics) {
    optionalParams.dataName = ParamsTypes.MetricsParams;
  }
  const requestData = getRequestData({
    itemsToAdd,
    queryParams,
    ...optionalParams
  });
  requestData.search_project_id = currentSearchProjectId;
  return requestData;
};

/**
 * @param {Object} request
 * @param {Object} itemsToAdd
 * @param {Object} queryParams
 * @param {function} [excludeMapperCallback]
 * @param {string} [dataName]
 * @returns {Object}
 */
const getRequestData = ({ itemsToAdd, queryParams, ...rest }) => {
  const filledData = {
    include: () => fillIncludedItems(itemsToAdd.data),
    exclude: () =>
      fillExcludeData({
        filteredItems: itemsToAdd,
        queryParams,
        ...rest
      })
  };

  return (filledData[itemsToAdd.type] && filledData[itemsToAdd.type]()) || {};
};

/**
 * @param {Number} searchProjectId
 * @param {FilteredItems} itemsToRemove
 * @param {Object} queryParams
 * @param {string} paramsType
 * @returns {OperationResult}
 */
export const removeItemsFromSearchProject = async ({
  searchProjectId,
  itemsToRemove,
  queryParams,
  paramsType,
  bulkId
}): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const data = {};
    if (itemsToRemove.type === SelectionType.Include) {
      data.entities = { ...fillIncludedItems(itemsToRemove.data) };
      const { job_orders: _, ...entities } = data.entities;
      data.requestData = { ...entities };
    } else if (itemsToRemove.type === SelectionType.Exclude) {
      data.requestData = {
        ...fillExcludeData({
          filteredItems: itemsToRemove,
          queryParams,
          dataName: paramsType,
          excludeMapperCallback: alternativeExcludeMapper
        })
      };
    }
    const dataToSend =
      paramsType === ParamsTypes.MetricsParams
        ? { data: { ...data.requestData, email_history_id: bulkId } }
        : {
            data: data.requestData
          };
    const response = await API.delete(
      SearchProjectsInventory.replace(/:id/, searchProjectId),
      dataToSend
    );
    result.success = response.status === HTTPStatusCodes.Ok;
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: 'Error while deleting search project items',
      body: getErrorMessage(error)
    };
  }
  return result;
};

/**
 * @typedef FilteredItems
 * @property {FilteredItemsData[]} data
 * @property {String} type
 * @property {Number} count
 */

/**
 * @param {Object} excludeParams
 * @param {FilteredItems} excludeParams.filteredItems - Object containing items to exclude, type and count
 * @param {Object} excludeParams.queryParams - Filters used on list
 * @param {String} [excludeParams.dataName] - In case you don't want the default data name
 * @param {ExcludeMapperCallback} [excludeParams.excludeMapperCallback] - Callback to set the excluded items
 * based on data an type
 *
 * @returns {Object} Contains params or query info and excluded items, in case user
 * used filters to remove some items
 */
const fillExcludeData = ({ filteredItems, queryParams, dataName, excludeMapperCallback }) => {
  const { data, count } = filteredItems;
  const { type, params } = {
    ...queryParams,
    params: { ...queryString.parse(queryParams.params), page: '1' }
  };
  const exclude = excludeMapperCallback
    ? excludeMapperCallback(data, type)
    : defaultExcludeMapper(data, type);
  return {
    [dataName || `${type}_query`]: {
      query: { ...params, perPage: count },
      exclude
    }
  };
};

/**
 * @callback ExcludeMapperCallback
 * @function ExcludeMapper
 * @param {FilteredItemsData[]} data
 * @param {String} type
 * @returns {Array} Contains objects with default properties id and role_id
 */
const defaultExcludeMapper = (data, type) =>
  type === 'name'
    ? data.map(item => ({ id: item.id, role_id: item.type_id }))
    : data.map(item => item.id);

const alternativeExcludeMapper = currentData =>
  currentData.map(item => ({
    id: item.id,
    item_search_project_type: parseInt(item.type_id, 10)
  }));
