import React, { useContext, useMemo, useState } from 'react';

const Context = React.createContext();

export const DrawerManagerProvider = ({ children }) => {
  const [drawerState, setDrawerState] = useState({
    activeDrawer: null,
    isOpen: false
  });

  const value = useMemo(() => {
    const closeDrawer = () => {
      setDrawerState(prevState => ({ ...prevState, activeDrawer: null, isOpen: false }));
    };

    const openDrawer = ({ activeDrawer }) => {
      setDrawerState(prevState => ({ ...prevState, isOpen: true, activeDrawer }));
    };

    return {
      drawerState,
      closeDrawer,
      openDrawer
    };
  }, [drawerState]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useDrawerManager = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useDrawerManager must be used within a DrawerManagerProvider');
  }
  return context;
};
