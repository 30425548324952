import React from 'react';
import { StatsItemsCard } from 'features/command-center/components/shared';
import { getAssignedCandidates, getAssignedJobOrders } from 'features/command-center/services';
import strings from 'strings';

const transformProps = params => {
  if (params.team) {
    return { teams: params.team };
  }
  if (params.recruiter) {
    return { employeeIds: params.recruiter };
  }
  return params;
};

export const AssignedInventoryStatsSummary = ({ requestParams }) => {
  const promise = async () => {
    const jobOrdersData = await getAssignedJobOrders(transformProps(requestParams));
    const candidatesData = await getAssignedCandidates(transformProps(requestParams));

    return [...jobOrdersData, ...candidatesData];
  };

  return (
    <StatsItemsCard
      header={strings.commandCenter.activity.assignedInventory.title}
      promise={() => promise()}
      subheader={strings.commandCenter.activity.assignedInventory.subtitle}
      textAlign="center"
    />
  );
};
