// @flow
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-plus-time-range';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { globalStyles } from 'GlobalStyles';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import moment from 'moment';
import { filtersToUi } from 'selectors/app';
import type {
  ContentPageLayoutProps as ContentPageLayoutPropsTypes,
  DatePeriod,
  Filters,
  FiltersSection
} from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import TextBox from 'UI/components/atoms/TextBox';
import type { TitleLabelProps } from 'UI/components/atoms/TitleLabel';
import TitleLabel from 'UI/components/atoms/TitleLabel';
import { When } from 'UI/components/atoms/When';
import ActiveFilters from 'UI/components/molecules/ActiveFilters';
import FooterActionsControlsLegacy from 'UI/components/molecules/FooterActionsControlsLegacy';
import CollapsiblePanel from 'UI/components/templates/CollapsiblePanel';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import SideFiltersLayoutConnected, {
  SideFiltersLayout
} from 'UI/components/templates/SideFiltersLayout';
import { AllFiltersDefinition } from 'UI/components/templates/SideFiltersLayout/filters';
import { DateFormats } from 'UI/constants/defaults';
import { getDefaultDashboardPeriod } from 'UI/utils';

import type { FilterGroup } from '../SideFiltersLayout/filters';

import { styles, useStyles } from './styles';

import 'UI/res/dateRangePicker.css';

type FiltersLayoutProps = {
  ContentPageLayoutProps?: ContentPageLayoutPropsTypes,
  dateRangerPickerProps?: any,
  defaultRange?: DatePeriod,
  disableDateRange: boolean,
  disableSideMenu: boolean,
  disableTimeRange: boolean,
  enableStore: boolean,
  filters: object,
  groups: FilterGroup[],
  HeaderActionComponent?: React.ReactNode,
  includeFilters: string[],
  isLoading: boolean,
  isPeriodClearable?: boolean,
  isSideMenuOpen: boolean,
  listSelector: any,
  onFiltersChange: (filter: Filters) => any,
  onMenuToggle: () => any,
  onPeriodChange: (period: DatePeriod) => any,
  onSearch: (section: FiltersSection, filters: Filters) => any,
  section: FiltersSection,
  sectionName?: string,
  subtitle: string,
  title: string,
  titleLabelProps?: TitleLabelProps,
  extraSelector: any
};

const DateRangeField = ({ selectedPeriod, dateFormat, onFocus }) => (
  <TextBox
    name="dateRange"
    label="Date Range"
    onFocus={onFocus}
    value={
      selectedPeriod
        ? `${moment(selectedPeriod.startDate).format(dateFormat)} - ${moment(
            selectedPeriod.endDate
          ).format(dateFormat)}`
        : 'All Time'
    }
    inputProps={{
      style: styles.datePickerField
    }}
  />
);

const DateRangeWrapper = ({ extraSelector, children }) =>
  extraSelector ? (
    <>
      <Grid item xs={5} sm={5} md={5} lg={5}>
        {extraSelector}
      </Grid>
      <Grid item xs={7} sm={7} md={7} lg={7}>
        {children}
      </Grid>
    </>
  ) : (
    children
  );

const FiltersLayout = ({
  children,
  ContentPageLayoutProps,
  dateRangerPickerProps,
  defaultRange,
  disableDateRange,
  disableSideMenu,
  disableTimeRange,
  enableStore,
  filters,
  groups,
  HeaderActionComponent,
  includeFilters,
  isLoading,
  isPeriodClearable,
  isSideMenuOpen,
  listSelector,
  onFiltersChange,
  onMenuToggle,
  onPeriodChange,
  onSearch,
  section,
  sectionName,
  subtitle,
  title,
  titleLabelProps,
  extraSelector
}: FiltersLayoutProps) => {
  const datePickerKey = 'selection';
  const classes = useStyles();
  const shouldShowPeriodClearButton = isPeriodClearable || defaultRange === null;
  const defaultPeriod = defaultRange !== undefined ? defaultRange : getDefaultDashboardPeriod();

  const [selectedPeriod, setSelectedPeriod] = useState<DatePeriod>(defaultPeriod);
  const [uiState, setUiState] = useState({
    isDatePickerOpen: false
  });
  const [previewRange, setPreviewRange] = useState([
    {
      ...defaultPeriod,
      key: datePickerKey
    }
  ]);

  const dateFormatToShow = disableTimeRange ? DateFormats.SimpleDate : DateFormats.SimpleDateTime;

  const openDateRange = () => setUiState(prevState => ({ ...prevState, isDatePickerOpen: true }));

  const handleDateRangeChange = item => {
    const { selection } = item || {};

    const isStartDateValid = moment(selection.startDate).isValid();
    const isEndDateValid = moment(selection.endDate).isValid();

    if (!isStartDateValid || !isEndDateValid) return;

    const endHours = selection.endDate.getHours();
    const endMinutes = selection.endDate.getMinutes();
    const timeStartsAtZero = endHours === 0 && endMinutes === 0;

    setPreviewRange([
      timeStartsAtZero
        ? {
            ...selection,
            endDate: moment(selection.endDate).endOf('day').toDate()
          }
        : selection
    ]);
  };

  const handleDateRangeCancel = () => {
    setUiState(prevState => ({ ...prevState, isDatePickerOpen: false }));
    selectedPeriod &&
      setPreviewRange([
        {
          key: datePickerKey,
          startDate: selectedPeriod.startDate,
          endDate: selectedPeriod.endDate
        }
      ]);
  };

  const handleDateRangeApply = () => {
    const { startDate, endDate } = previewRange[0];
    setUiState(prevState => ({ ...prevState, isDatePickerOpen: false }));
    const newPeriod = {
      startDate,
      endDate
    };
    setSelectedPeriod(newPeriod);
    onPeriodChange && onPeriodChange(newPeriod);
  };

  const handleClearPeriod = () => {
    setUiState(prevState => ({ ...prevState, isDatePickerOpen: false }));
    setSelectedPeriod(null);
    setPreviewRange([{ key: datePickerKey }]);
    onPeriodChange && onPeriodChange(null);
  };

  const handleFilterRemove = (filterName: string, valueToRemove: any) => {
    const currentFilter = filters[filterName];
    const filterDefinition = AllFiltersDefinition.find(({ name }) => name === filterName);
    const filtersToRemove = !filterDefinition?.dependent
      ? [filterName]
      : [filterName, filterDefinition.dependent];

    const currentFilterValue = currentFilter.value;
    if (!isArray(currentFilterValue)) {
      onFiltersChange(omit(filters, filtersToRemove));
    } else {
      const filteredArray = currentFilterValue.filter(each => (each.id || each) !== valueToRemove);
      const newFilters =
        filteredArray.length === 0
          ? omit(filters, filtersToRemove)
          : {
              ...filters,
              [filterName]: {
                ...currentFilter,
                value: filteredArray
              }
            };
      onFiltersChange(newFilters);
    }
  };

  const handleFiltersReset = () => onFiltersChange && onFiltersChange({});

  const Component = enableStore ? SideFiltersLayoutConnected : SideFiltersLayout;

  const extraProps = !enableStore
    ? {
        onSearch,
        initialFilters: filtersToUi(filters)
      }
    : {};

  const titleGridConfig = extraSelector
    ? {
        xs: 12,
        sm: 5,
        lg: 5
      }
    : null;

  const actionsBarConfig = extraSelector
    ? {
        xs: 12,
        sm: 7,
        lg: 7,
        spacing: 2,
        container: true
      }
    : null;

  const shouldDisplayActionBar =
    !disableDateRange ||
    (!disableDateRange && uiState.isDatePickerOpen) ||
    (!isLoading && HeaderActionComponent);

  return (
    <>
      <ContentPageLayout
        customTitleGridConfig={titleGridConfig}
        className={classes.wrapper}
        text={title}
        sectionName={sectionName}
        titleLabelProps={{
          subtitle,
          subtitleProps: {
            classes: subtitle ? { h2: classes.h2 } : null
          },
          ...titleLabelProps
        }}
        actionsBarConfig={actionsBarConfig}
        actionsBar={
          shouldDisplayActionBar ? (
            <>
              {!disableDateRange && (
                <DateRangeWrapper extraSelector={extraSelector}>
                  <DateRangeField
                    selectedPeriod={selectedPeriod}
                    dateFormat={dateFormatToShow}
                    onFocus={openDateRange}
                  />
                </DateRangeWrapper>
              )}
              {!disableDateRange && uiState.isDatePickerOpen && (
                <div className={classes.datePickerContainer}>
                  <div className={classes.dateRangeContainer}>
                    <DateRangePicker
                      onChange={handleDateRangeChange}
                      showSelectionPreview
                      inputRanges={[]}
                      months={2}
                      ranges={previewRange}
                      showTime={!disableTimeRange}
                      direction="horizontal"
                      {...dateRangerPickerProps}
                    />
                    <div className={classes.dateRangeToolbar}>
                      <FooterActionsControlsLegacy
                        secondaryAction={handleDateRangeCancel}
                        saveAction={false}
                        secondaryProps={{ size: 'small' }}
                        primaryProps={{
                          onClick: handleDateRangeApply,
                          text: 'Apply filters',
                          size: 'small'
                        }}
                        customThirdButton={
                          shouldShowPeriodClearButton ? (
                            <FPActionButton
                              text="Clear"
                              variant="outlined"
                              onClick={handleClearPeriod}
                              size="small"
                            />
                          ) : undefined
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              <When condition={!isLoading && HeaderActionComponent}>
                <Grid item>{HeaderActionComponent}</Grid>
              </When>
            </>
          ) : null
        }
        {...ContentPageLayoutProps}
      >
        {listSelector && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5} md={4} lg={3} xl={1}>
              <div className={classes.listSelectorContainer}>
                {isLoading ? (
                  <Skeleton style={globalStyles.skeletonItem} color="A200" />
                ) : (
                  listSelector
                )}
              </div>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.activeFiltersContainer}>
            <ActiveFilters
              filters={filters}
              isLoading={isLoading}
              onFilterRemove={handleFilterRemove}
              onReset={handleFiltersReset}
            />
          </Grid>
        </Grid>
        {children}
      </ContentPageLayout>
      {!disableSideMenu && (
        <CollapsiblePanel
          isSideMenuOpen={isSideMenuOpen}
          mode="overlay"
          contentStyles={{ ...styles.sectionContent }}
          onToggle={onMenuToggle}
        >
          <div className={classes.filtersTitleContainer}>
            <TitleLabel fontSize={26} text="FILTERS" textTransform="uppercase" />
          </div>
          <Component
            searchButtonText="Apply"
            section={section}
            includeFilters={includeFilters}
            groups={groups}
            defaultFilters={{}}
            areFiltersRequired={false}
            contentClassName={classes.filtersContainer}
            {...extraProps}
          />
        </CollapsiblePanel>
      )}
    </>
  );
};

FiltersLayout.defaultProps = {
  ContentPageLayoutProps: {},
  dateRangerPickerProps: undefined,
  defaultRange: undefined,
  disableDateRange: false,
  disableSideMenu: false,
  disableTimeRange: false,
  enableStore: true,
  groups: [],
  HeaderActionComponent: null,
  includeFilters: [],
  isLoading: false,
  isPeriodClearable: false,
  listSelector: undefined,
  onSearch: () => {},
  sectionName: '',
  titleLabelProps: undefined,
  extraSelector: undefined
};

export default FiltersLayout;
