// @flow
import strings from 'strings';

import { FeatureFlags } from './featureFlags';

export const AppName = 'FortPac';
export const FORTPAC_SUPPORT_LINK =
  window.GPAC_ENV?.SUPPORT_GLIP_URL || process.env.REACT_APP_SUPPORT_GLIP_URL;

export const drawerAnchor = 'right';

export const usCenterCoordinates = {
  latitude: 39.052136,
  longitude: -94.71583
};

export const DELAY_BOUNCE_TIME = 500;

export const waitingTimeBeforeRedirect = 1000;

/**
 * @typedef {object} DateFormats
 * @property {string} DetailDate - 'MMMM DD, YYYY'
 * @property {string} DetailDateTime - 'MMMM DD, hh:mm A'
 * @property {string} SimpleDate - 'MM/DD/YYYY'
 * @property {string} SimpleDateHyphen - 'MM-DD-YYYY'
 * @property {string} SimpleDateTime - 'MM/DD/YYYY hh:mm A'
 * @property {string} SimpleDateTimeHyphen - 'MM-DD-YYYY hh:mm A'
 * @property {string} DayDate - 'dddd, MM/DD/YYYY'
 * @property {string} DayAndLargeDate - 'dddd MMMM DD'
 * @property {string} QueryFormat - 'YYYY-MM-DD HH:mm:ss'
 * @property {string} QueryShortFormat - 'YYYY-MM-DD'
 * @property {string} MonthDay - 'MMM DD'
 * @property {string} MonthDayYear - 'MMM DD, YYYY'
 * @property {string} MonthDayYearTime - 'MMM DD, YYYY hh:mm A'
 * @property {string} MonthDayTime - 'MMM DD, hh:mm A'
 * @property {string} MonthDayTimeHyphen - 'MMM DD - hh:mm A'
 * @property {string} MonthNumberDay - 'MMMM Do'
 * @property {string} SendoutTemplateDate - 'dddd, MMMM DD, hh:mm A'
 * @property {string} SimpleTime - 'hh:mm A'
 * @property {string} SimpleMonthNumberDay - 'MMM Do'
 * @property {string} InterviewTemplateDate - 'ddd, MMM Do'
 * @property {string} SimpleTime24Format - 'HH:mm'
 * @property {string} MonthDayNumberYear - 'MMMM Do, YYYY
 */
export const DateFormats = {
  DateTimeWithTimeZone: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  DayAndLargeDate: 'dddd MMMM DD',
  DayDate: 'dddd, MM/DD/YYYY',
  DetailDate: 'MMMM DD, YYYY',
  DetailDateTime: 'MMMM DD, hh:mm A',
  InterviewTemplateDate: 'ddd, MMM Do',
  MonthDay: 'MMM DD',
  MonthDayNumberYear: 'MMMM Do, YYYY',
  MonthDayTime: 'MMM DD, hh:mm A',
  MonthDayTimeHyphen: 'MMM DD - hh:mm A',
  MonthDayYear: 'MMM DD, YYYY',
  MonthDayYearTime: 'MMM DD, YYYY hh:mm A',
  MonthNumberDay: 'MMMM Do',
  QueryFormat: 'YYYY-MM-DD HH:mm:ss',
  QueryShortFormat: 'YYYY-MM-DD',
  SearchProjectSplit: 'YYYY-MM-DDThh:mm:ss',
  SendoutTemplateDate: 'dddd, MMMM DD, hh:mm A',
  SimpleDate: 'MM/DD/YYYY',
  SimpleDateHyphen: 'MM-DD-YYYY',
  SimpleDateTime: 'MM/DD/YYYY hh:mm A',
  SimpleDateTimeHyphen: 'MM-DD-YYYY hh:mm A',
  SimpleMonthNumberDay: 'MMM Do',
  SimpleTime: 'hh:mm A',
  SimpleTime24Format: 'HH:mm'
};

export const FilterType = {
  Autocomplete: 'autocomplete',
  Text: 'text',
  Select: 'select',
  Search: 'search',
  Input: 'input',
  Switch: 'switch',
  DateRange: 'dateRange',
  Date: 'date',
  Range: 'range'
};

export const FilterRangeSufix = {
  From: 'From',
  To: 'To'
};

export const HideMode = {
  Hidden: 'hidden',
  Unmounted: 'unmounted'
};

export const GranularityFormats = {
  hour: 'EEE dd, HH aa',
  hour24: 'HH aa',
  day: 'MMM dd',
  week: 'MMM dd, yyyy',
  month: 'MMM yyyy'
};

export const PageTitles = {
  BulkEmail: `${AppName} | Bulk Emails`,
  Candidate: `${AppName} | Candidates`,
  CandidateCreate: `${AppName} | New Candidate`,
  CandidateProfile: `${AppName} | Candidate Profile`,
  Collections: `${AppName} | AR & Collections`,
  CommandCenterActivity: `${AppName} | CC Activity`,
  CommandCenterOverview: `${AppName} | CC Overview`,
  CommandCenterDominateSheet: `${AppName} | CC Dominate Sheet`,
  Company: `${AppName} | Companies`,
  CompanyCreate: `${AppName} | New Company`,
  CompanyProfile: `${AppName} | Company Profile`,
  Contact: `${AppName} | Contacts`,
  ContactCreate: `${AppName} | Contact Create`,
  ContactProfile: `${AppName} | Contact Profile`,
  Dashboard: `${AppName} | Dashboard`,
  DashboardBulk: `${AppName} | Bulk Dashboard`,
  DashboardFeeAgreementsBoard: `${AppName} | Fee Agreements Board`,
  DashboardPhone: `${AppName} | Calls & Texts Dashboard`,
  DashboardSendoutBoard: `${AppName} | Sendouts Board`,
  DashboardSendoutLeaders: `${AppName} | Sendouts Leaderboard`,
  DashboardToolsUsage: `${AppName} | Tools Usage Report`,
  Default: 'FortPac | gpac',
  EditCandidateDataSheet: `${AppName} | Edit Candidate Data Sheet`,
  EditDataSheet: `${AppName} | Edit Data Sheet`,
  FeeAgreements: `${AppName} | Fee Agreements`,
  HiringAuthority: `${AppName} | Hiring Authority Profile`,
  Home: `${AppName} | Home`,
  Industries: `${AppName} | Industries`,
  JobOrder: `${AppName} | Job Orders`,
  JobOrderCreate: `${AppName} | New Job Order`,
  JobOrderProfile: `${AppName} | Job Order Profile`,
  Login: `${AppName} | Login`,
  Map: `${AppName} | PIL Map`,
  NotFound: `${AppName} | Not Found`,
  Placements: `${AppName} | Placements`,
  Roster: `${AppName} | Roster`,
  SearchProject: `${AppName} | Search Project`,
  SearchProjectPreview: `${AppName} | Search Project Preview`,
  Sendouts: `${AppName} | Sendouts`,
  SendoutsFromChannelPartner: `${AppName} | Channel Partner Sendouts`,
  Teams: `${AppName} | Teams`
};

export const DrawerName = {
  Notifications: 'notifications',
  OperatingMetrics: 'metrics',
  FeeAgreements: 'feeagreement'
};

export const RecruiterBarMode = {
  Compact: 'compact',
  Large: 'large'
};

export const DefaultProfile = {
  id: null,
  title: null,
  name: null,
  contact: null,
  personalInformation: null,
  specialty: null,
  subspecialty: null,
  position: null,
  email: null,
  current_company: null,
  sourceType: null,
  recruiter: null,
  coach: null,
  additionalRecruiters: [],
  company: null,
  address: null,
  source: null,
  signed: null,
  website: null,
  free_game: false
};

export const NotificationType = {
  Inventory: 'inventory',
  OperatingMetrics: 'operating_metrics',
  FeeAgreement: 'fee_agreement',
  SuccessRules: 'success_rules',
  Social: 'social'
};

export const NotificationsVisibilityOptions = {
  All: 'all',
  Unread: 'unread',
  Read: 'read'
};

export const MaxNumberOfAttachments = 15;

export const defaultMaxFileSize = 26214400;

export const defaultValidExtNames = [
  'csv',
  'doc',
  'docx',
  'jpeg',
  'jpg',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'xls',
  'xlsx'
];

export const ExpandedTreeView = ['mine'];

export const BulkMenuItems = {
  Sent: 'sent',
  Template: 'template',
  Drafts: 'drafts',
  OptOut: 'optout',
  // TODO: should remove create template and template edition from this const
  CreateTemplate: 'createTemplate',
  Schedule: 'schedule',
  TemplateEdition: 'templateEdition'
};

export const BulkScopes = {
  Marketing: 1,
  Recruiting: 2,
  General: 3,
  ById: {
    1: 'Marketing',
    2: 'Recruiting',
    3: 'General'
  }
};

export const BulkMarketingOptions = [
  {
    label: strings.bulkEmails.forms.marketingOptions.blockCompanies,
    name: 'blockCompanies'
  },
  {
    label: strings.bulkEmails.forms.marketingOptions.blockSigned,
    name: 'blockSigned'
  },
  {
    label: strings.bulkEmails.forms.marketingOptions.allowMarketingCandidates,
    name: 'allowMarketingCandidates'
  }
];

export const BulkRecruitingOptions = [
  {
    label: strings.bulkEmails.forms.recruitingOptions.allowRecruitHirings,
    name: 'allowRecruitHirings'
  }
];

export const BulkBlockOptions = {
  intervals: [
    { id: '10,days', title: '10 Days' },
    { id: '15,days', title: '15 Days' },
    { id: '30,days', title: '30 Days' }
  ],
  resend: [
    { id: 'true', title: 'On' },
    { id: 'false', title: 'Off' }
  ]
};

export const LimitLocalDataOfSelectors = {
  zip: 200,
  city: 500
};

export const SignTypes = {
  signed: 0,
  client: 1,
  notSigned: 2,
  ById: {
    0: 'signed',
    1: 'client',
    2: 'not signed'
  }
};
export const RingCentralLogTypes = {
  Call: {
    id: 1,
    title: 'Call'
  },
  Text: {
    id: 2,
    title: 'Text'
  }
};

export const RingCentralOriginActions = {
  Default: 'Default',
  MultiplePhonesDropdown: 'MultiplePhonesDropdown'
};

export const ActivityLogTypes = {
  BulkEmail: 0,
  Email: 1,
  SMS: 2,
  Call: 3,
  Sendout: 4,
  JobPosting: 5,
  CheckPersonalInventory: 6,
  CheckOfficeInventory: 7,
  CheckPCRAndFortPacInventory: 8,
  CreatePresentation: 9,
  SendInmails: 11,
  CreateRollOrAddRollUp: 12,
  CreateCallPlan: 13,
  GeneralUpdate: 14,
  PendingOffer: 15,
  OfferSent: 16,
  OfferAccepted: 17,
  ReferencesCompleted: 18,
  ReferencesReleaseFormSent: 19,
  Interview: 20,
  Sendover: 21,
  ZoomInfoEmail: 22,
  LinkedinMessages: 23,
  AlertsSetUp: 24,
  LinkedinStatusUpdate: 25,
  AutomaticCall: 26,
  AutomaticText: 27
};

export const FeeAgreementDefaultValues = {
  FeePercent: 33.33,
  GuaranteeDays: 30
};

export const FeeAgreementFileCategoryId = 14;
/**
 * Correct API URL
 * works in dev & prod environments
 */
export const EnvApiUrls = {
  MAIN: `${(window.GPAC_ENV && window.GPAC_ENV.API_URL) || process.env.REACT_APP_API_URL}`,
  WS: `${(window.GPAC_ENV && window.GPAC_ENV.API_WS_URL) || process.env.REACT_APP_API_WS_URL}`,
  MAIN_VERSIONED: window.GPAC_ENV?.API_URL_VERSIONED || process.env.REACT_APP_API_URL_VERSIONED,
  API_URL_AWS: window.GPAC_ENV?.SECONDARY_API_URL || process.env.REACT_APP_SECONDARY_API_URL,
  API_KEY_AWS: window.GPAC_ENV?.SECONDARY_API_KEY || process.env.REACT_APP_SECONDARY_API_KEY,
  GPAC_CDN_URL: window.GPAC_ENV?.GPAC_CDN_URL || process.env.REACT_APP_GPAC_CDN_URL
};

export const WebSocketNamespaces = {
  BulkEmail: '/bulk-email',
  PhoneActivityLog: '/phone-activity-log',
  DashboardPhoneLoading: '/dashboard-phone-loading'
};

export const PreviewTypes = {
  BulkEmail: 'bulk-email-preview'
};

export const activityAvatars = Object.keys(ActivityLogTypes);

export const RowActions = Object.freeze({
  Call: 'call',
  Delete: 'delete',
  Download: 'download',
  Edit: 'edit',
  Email: 'email',
  SendReminder: 'sendReminder',
  Text: 'text',
  View: 'view'
});

export const CommunicationActionPreset = [
  {
    name: RowActions.Call,
    value: 'phone'
  },
  {
    name: RowActions.Text,
    value: 'phone'
  },
  {
    name: RowActions.Email,
    value: 'email'
  }
];

export const SearchProjectPrivacyScopes = {
  private: {
    id: 'private',
    title: 'Private',
    value: true
  },
  public: {
    id: 'public',
    title: 'Public',
    value: false
  },
  getPrivacyState(isPrivate) {
    return isPrivate ? this.private : this.public;
  }
};

export const SelectionType = {
  Exclude: 'exclude',
  Include: 'include'
};

export const DefaultNewCandidateFileCategories = [
  {
    id: 4,
    title: 'Resume'
  },
  {
    id: 17,
    title: 'Cover Letter'
  },
  {
    id: 15,
    title: 'Portfolio'
  },
  {
    id: 1,
    title: 'Blue Sheet'
  }
];

export const defaultProfileColumnsConfig = {
  display: true,
  sort: false
};

export const DefaultNewJobOrderFileCategories = [
  {
    id: 2,
    title: 'White Sheet'
  },
  {
    id: 18,
    title: 'Job Description'
  },
  {
    id: 19,
    title: 'Other Files'
  }
];

export const SeverityOptions = {
  error: 'error',
  success: 'success',
  warning: 'warning'
};

export const YesNo = {
  Yes: 'yes',
  No: 'no'
};

export const TypeHistoryItem = {
  Custom: 'custom',
  Sendout: 'sendout'
};

export const Yes = {
  id: '1',
  title: 'Yes'
};
export const No = {
  id: '0',
  title: 'No'
};

export const BinaryOptions = [Yes, No];

export const MarkerTypeClass = {
  0: 'marker-type-alpha',
  1: 'marker-type-poejo',
  2: 'marker-type-cant-help'
};

export const MarkerCompanyClass = {
  0: 'marker-company-signed',
  1: 'marker-company-client',
  2: 'marker-company-not-signed'
};

export const StringsBooleanMap = {
  true: true,
  false: false,
  '1': true,
  '0': false
};

export const COMPANY_RELATIONSHIP_TYPES = {
  PARENT: 'PARENT',
  CHILD: 'CHILD',
  SIBLING: 'SIBLING'
};

export const COMPANY_RELATIONSHIP_KEY = {
  parentCompany: 'parentCompany',
  childCompanies: 'childCompanies',
  siblingCompanies: 'siblingCompanies'
};

export const USER_FILTER_IDS = {
  Mine: 0,
  MyIndustries: 1,
  MyTeam: 2,
  All: 3,
  MyCollaborations: 4,
  FreeGame: 5,
  MyInventory: 6,
  MyRegion: 7
};

export const OPT_OUT_COLUMNS = ['opt_out_date', 'opt_out_reason', 'is_opted_out'];

export const FP_SECTIONS = {
  Bulk: 'bulk',
  Profile: 'profile',
  Map: 'map',
  EntityCreation: 'entityCreation',
  QuickView: 'quickView'
};

export const BULK_RESTRICTION_RULE = {
  defaultRestriction: 'defaultRestriction',
  noQuotaAvailable: 'noQuotaAvailable',
  quotaNearlyReached: 'quotaNearlyReached',
  scheduleCloseNearScheduled: 'scheduleCloseNearScheduled',
  scheduleCloseNearSent: 'scheduleCloseNearSent',
  sendNearSent: 'sendNearSent',
  sendNearScheduled: 'sendNearScheduled'
};

export const BULK_RESTRICTION_IDS = {
  noQuotaAvailable: 'bulk:sendNow:noQuotaAvailable',
  scheduleCloseNearScheduled: 'bulk:schedule:scheduleHourRange',
  scheduleCloseNearSent: 'bulk:schedule:sentBulkHourRange',
  sendNearSent: 'bulk:sendNow:sentBulkHourRange',
  sendNearScheduled: 'bulk:sendNow:scheduleHourRange'
};

export const COLUMNS_TO_REMOVE_BY_FEATURE_FLAG = {
  OPT_OUT: {
    columnsToRemove: OPT_OUT_COLUMNS,
    featureFlag: FeatureFlags.OptOutFilter
  },
  EMAIL_VALIDATION: {
    columnsToRemove: ['is_valid_email'],
    featureFlag: FeatureFlags.EmailValidationColumn
  },
  PHONES: {
    columnsToRemove: ['phone', 'mobile'],
    featureFlag: FeatureFlags.CandidatesMultiplePhones
  }
};

export const EMAIL_VALIDATION_STATUSES = {
  RISKY: 'risky',
  INVALID: 'invalid',
  VALID: 'valid'
};

export const ARCHIVE_AVAILABLE_DAYS =
  process.env.REACT_APP_SEARCH_PROJECT_ARCHIVE_AVAILABILITY_DAYS ||
  window.GPAC_ENV?.SEARCH_PROJECT_ARCHIVE_AVAILABILITY_DAYS;

export const MY_TEMPLATES_FOLDER_ID = 'Mine';
export const ARCHIVE_TEMPLATES_FOLDER_NAME = 'Archived';

export const TEMPLATE_ENTITY_TYPES = {
  Root: 'root',
  Folder: 'folder',
  Template: 'template'
};

export const TREE_VIEW_ENTITY_TYPES = {
  folder: 'Folder',
  item: 'Item'
};

export const DEFAULT_SEARCH_PROJECT_FOLDERS_EXCLUDE_IDS = [
  'MyCollaborations',
  'AllSearchProjects',
  'Archived'
];

export const SEARCH_PROJECT_ROOT_FOLDERS = {
  mine: { id: 'MySearchProjects', name: strings.searchProjects.mainPage.filtersTitles.mine },
  archived: { id: 'Archived', name: strings.searchProjects.mainPage.filtersTitles.archived }
};

export const TargetCompaniesOptions = [
  { id: true, title: strings.companies.creation.targetCompany.options.showTargetCompanies },
  { id: false, title: strings.companies.creation.targetCompany.options.excludeTargetCompanies }
];
