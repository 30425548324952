// @flow
import React, { useCallback, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { resetFilters, searchInventory, selectEntity, selectRecruiter } from 'actions/map';
import { useSearchProject } from 'hooks/searchProject';
import { useMap } from 'hooks/useMap';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { filtersToParams } from 'selectors/app';
import FPMap from 'UI/components/atoms/FPMap';
import SaveButton from 'UI/components/atoms/SaveButton';
import { When } from 'UI/components/atoms/When';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import PopUpContent from 'UI/components/organisms/PopUpContent';
import { SearchProjectItemType } from 'UI/constants/entityTypes';

import { useStyles } from './styles';

type MapBoxProps = {
  activeTab: number,
  entityType?: string,
  filters: any,
  hasLoaded: boolean,
  isDigActive: boolean,
  isLoading: boolean,
  isSideMenuOpen: boolean,
  markers: Array<any>,
  selectedEntity?: any,
  selectedRecruiter?: any
};

const MINIMUM_ZOOM_FOR_SEARCH_AREA = 3.5;

const MapBox = ({
  activeTab,
  entityType,
  filters,
  hasLoaded,
  isDigActive,
  isLoading,
  isSideMenuOpen,
  markers,
  selectedEntity,
  selectedRecruiter
}: MapBoxProps) => {
  const mapRef = useRef(null);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);

  const filtersObj = filtersToParams(filters);

  const entityId = {
    candidate: {
      id: SearchProjectItemType.Candidate,
      name: 'Candidate'
    },
    joborder: {
      id: SearchProjectItemType.HiringAuthority,
      name: 'Job Order'
    },
    company: {
      id: SearchProjectItemType.Company,
      name: 'Company'
    }
  };

  const filteredItems = {
    type: 'include',
    data: [
      {
        id: selectedEntity?.id,
        type_id: entityId[entityType]?.id,
        type: entityId[entityType]?.name
      }
    ],
    count: 1
  };
  const paramsStringified = queryString.stringify(filtersObj, { arrayFormat: 'comma' });
  const queryParams = {
    params: paramsStringified,
    type: 'map'
  };

  const cleanUp = useCallback(() => {
    dispatch(selectRecruiter(null));
    dispatch(selectEntity(null));
    dispatch(resetFilters());
  }, [dispatch]);

  const { handleClosePopUp, handleMarkerEnter, handleViewportChange, popupInfo, viewport } = useMap(
    { selectedRecruiter, markers, selectedEntity, cleanUp }
  );

  const { SearchProjectAction, SearchProjectForms } = useSearchProject({
    filteredItems,
    queryParams
  });

  const renderSearchOnThisAreaButton = () => {
    const btnText = 'Search on this area';
    const areaFilters = filters;

    const handleOnSave = () => {
      const { _ne: northEast, _sw: southWest } = mapRef.current.getMap().getBounds();
      const coordinates = {
        ne: northEast,
        sw: southWest
      };
      areaFilters.area = { paramName: 'area', value: JSON.stringify(coordinates) };
      areaFilters.countries = { paramName: 'countryIds', value: [] };
      areaFilters.states = { paramName: 'stateIds', value: [] };
      areaFilters.cities = { paramName: 'cityIds', value: [] };
      areaFilters.cityRadius = { paramName: 'cityRadius', value: '' };
      areaFilters.zips = { paramName: 'zips', value: [] };
      areaFilters.radius = { paramName: 'radius', value: '' };
      dispatch(searchInventory(areaFilters));
    };

    if (!isEmpty(filters) && markers.length && viewport.zoom >= MINIMUM_ZOOM_FOR_SEARCH_AREA) {
      return (
        <div className={classes.searchBtnContainer} id="search-btn-container">
          <SaveButton
            className={classes.searchBtn}
            disabled={isLoading}
            initialText={btnText}
            isSaving={isLoading}
            isSuccess={hasLoaded}
            onClick={handleOnSave}
            onProgressText="Searching ..."
            onSuccessText={btnText}
            type="button"
          />
        </div>
      );
    }
    return null;
  };

  const displayFiltersClass = isSideMenuOpen ? classes.filtersOpen : classes.filtersClosed;

  const onQuickViewClick = () => {
    setIsQuickViewOpen(!isQuickViewOpen);
  };

  const handleEnterMarker = info => {
    dispatch(selectEntity(info));
    handleMarkerEnter(info);
  };

  const handleClosePopup = () => {
    dispatch(selectEntity(null));
    handleClosePopUp();
  };

  return (
    <div className={`${classes.mapContainer} ${displayFiltersClass}`}>
      <FPMap
        entityType={entityType}
        isDigActive={isDigActive}
        mapRef={mapRef}
        markers={markers}
        onClosePopup={handleClosePopup}
        onMapClick={handleClosePopup}
        onMarkerEnter={handleEnterMarker}
        onViewportChange={handleViewportChange}
        popupInfo={popupInfo}
        viewport={viewport}
        renderPopupContent={() => (
          <PopUpContent
            info={popupInfo}
            isDigActive={isDigActive}
            entityType={entityType}
            onQuickViewClick={onQuickViewClick}
            SearchProjectAction={SearchProjectAction}
          />
        )}
      >
        {activeTab === 1 && renderSearchOnThisAreaButton()}
      </FPMap>
      <SearchProjectForms />
      <When condition={entityId && isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: isQuickViewOpen
          }}
          entityType={entityType}
          onClose={onQuickViewClick}
          id={selectedEntity?.id}
        />
      </When>
    </div>
  );
};

MapBox.defaultProps = {
  entityType: '',
  selectedRecruiter: undefined,
  selectedEntity: null
};

const mapStateToProps = ({ map }) => {
  return {
    activeTab: map.ui.activeTab,
    entityType: map.domain.filters.entityType?.value?.id,
    filters: map.domain.filters,
    hasLoaded: map.ui.hasLoaded,
    isDigActive: map.ui.activeTab === 0,
    isLoading: map.ui.isLoading,
    isSideMenuOpen: map.ui.isSideMenuOpen,
    markers: map.domain.markers,
    selectedEntity: map.ui.selectedEntity,
    selectedRecruiter: map.ui.selectedRecruiter
  };
};

const MapBoxConnected = connect(mapStateToProps, null)(MapBox);

export default MapBoxConnected;
