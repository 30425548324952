import { capitalizeName } from 'features/command-center/utils';

export const formatResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    recruiterName: {
      empNo: item.empNo,
      name: capitalizeName(item.name)
    },
    team: {
      managerEmpNo: item.managerEmpNo,
      team: capitalizeName(item.team)
    },
    inMails: {
      inMails: item.inMails,
      inMailsGoals: item.inMailsGoals
    },
    avgInMails: {
      avgInMails: item.avgInMails,
      avgInMailsGoals: item.avgInMailsGoals
    },
    bulks: {
      bulks: item.bulks,
      bulksGoal: item.bulksGoal
    },
    callsTime: {
      callsTime: item.callsTime,
      callsTimeGoal: item.callsTimeGoal
    },
    calls: {
      calls: item.calls,
      callsGoal: item.callsGoal
    },
    peopleReached: {
      peopleReached: item.peopleReached,
      peopleReachedGoal: item.peopleReachedGoal
    },
    posts: {
      todayLivePosts: item.todayLivePosts,
      livePostingsDailyGoal: item.livePostingsDailyGoal
    },
    sendouts: {
      sendouts: item.sendouts,
      sendoutsGoal: item.sendoutsGoal
    },
    productionYTD: item.productionYtd,
    cashInYTD: item.cashInYtd,
    outStandingBillings: item.outstandingBillings
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};
