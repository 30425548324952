import React, { useCallback, useMemo } from 'react';
import { Widget } from 'features/command-center';
import { METRICS_MAP } from 'features/command-center/constants';
import { useDrawerManager } from 'features/command-center/contexts';
import { getFinanceHealthDistribution } from 'features/command-center/services';
import strings from 'strings';

import { DRAWER_KEYS } from '../../shared/Drawers';

import { renderContent } from './HealthDistribution.utils';

export const HealthDistribution = ({ requestParams, hasDrillDown }) => {
  const drawerManager = useDrawerManager();

  const render = useCallback(
    ([{ data }]) => {
      if (!hasDrillDown) return renderContent({ data });

      const drawerHandlers = {
        [METRICS_MAP.InTraining]: () => {
          drawerManager.openDrawer({ activeDrawer: DRAWER_KEYS.HealthDistributionInTraining });
        },
        [METRICS_MAP.AtRisk]: () => {
          drawerManager.openDrawer({ activeDrawer: DRAWER_KEYS.HealthDistributionAtRisk });
        },
        [METRICS_MAP.Behind]: () => {
          drawerManager.openDrawer({ activeDrawer: DRAWER_KEYS.HealthDistributionBehind });
        },
        [METRICS_MAP.Under3Months]: () => {
          drawerManager.openDrawer({ activeDrawer: DRAWER_KEYS.HealthDistributionUnder3Months });
        },
        [METRICS_MAP.OnTrack]: () => {
          drawerManager.openDrawer({ activeDrawer: DRAWER_KEYS.HealthDistributionOnTrack });
        },
        [METRICS_MAP.Headcount]: () => {
          drawerManager.openDrawer({ activeDrawer: DRAWER_KEYS.HealthDistributionHeadcount });
        }
      };
      return renderContent({ data, drawerHandlers });
    },
    [drawerManager, hasDrillDown]
  );

  const cachedPromises = useMemo(
    () => [() => getFinanceHealthDistribution(requestParams)],
    [requestParams]
  );

  return (
    <Widget
      header={strings.commandCenter.finance.healthDistribution.title}
      subheader={strings.commandCenter.finance.healthDistribution.subtitle}
      promises={cachedPromises}
    >
      {render}
    </Widget>
  );
};
