import moment from 'moment';

export const getChartDayLabels = () => {
  return [
    { v: 0, f: 'Mon' },
    { v: 1, f: 'Tue' },
    { v: 2, f: 'Wed' },
    { v: 3, f: 'Thu' },
    { v: 4, f: 'Fri' }
  ];
};

export const getChartMonthLabels = isCurrentYear => {
  const monthLabels = [
    { v: 0, f: 'Jan' },
    { v: 1, f: 'Feb' },
    { v: 2, f: 'Mar' },
    { v: 3, f: 'Apr' },
    { v: 4, f: 'May' },
    { v: 5, f: 'Jun' },
    { v: 6, f: 'Jul' },
    { v: 7, f: 'Aug' },
    { v: 8, f: 'Sep' },
    { v: 9, f: 'Oct' },
    { v: 10, f: 'Nov' },
    { v: 11, f: 'Dec' }
  ];

  const totalMonths = isCurrentYear ? moment().month() : 12;
  return monthLabels.slice(0, totalMonths + 1);
};

const convertSecondsToMinutes = value => {
  if (!value) return 0;
  return Math.floor(value / 60);
};

export const formatTrendsResponse = ({ response, key, goalKey, label }) => {
  const xSeriesHeader = ['Days', label];
  if (!response?.length) {
    return [xSeriesHeader, ['', 0]];
  }

  const isKeyPhoneTime = key === 'phoneTime';
  const hasGoalKey = Boolean(goalKey);

  const formatDate = dateStr => {
    const [year, month, day] = dateStr.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString('default', { weekday: 'short' });
  };

  const formatItem = item => {
    const dayOfWeek = formatDate(item.date);
    const value = isKeyPhoneTime ? convertSecondsToMinutes(item[key]) : item[key];
    return hasGoalKey ? [dayOfWeek, value, item[goalKey]] : [dayOfWeek, value];
  };

  const formattedData = [
    hasGoalKey ? [...xSeriesHeader, 'Goal'] : xSeriesHeader,
    ...response.map(formatItem)
  ];

  return formattedData;
};

export const calculatePercentage = ({ total, goal }) => {
  const totalNumber = Number(total);
  const goalNumber = Number(goal);

  const isValidTotal = !Number.isNaN(totalNumber);
  const isValidGoal = !Number.isNaN(goalNumber) && goalNumber !== 0;

  return isValidTotal && isValidGoal ? totalNumber / goalNumber : 0;
};
