import React from 'react';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';

const { healthDistributionUnder3Months } = strings.commandCenter.drawers;
export const HealthDistributionUnder3Months = () => {
  return <div>HealthDistributionUnder3Months</div>;
};

export const HealthDistributionUnder3MonthsConfig = {
  title: 'Under 3 Months Recruiters',
  subHeader: (
    <>
      <Typography
        dangerouslySetInnerHTML={{
          __html: healthDistributionUnder3Months.subTitle.replace(
            '{healthStatus}',
            '<strong>Under 3 Months</strong>'
          )
        }}
      />
    </>
  ),
  component: HealthDistributionUnder3Months
};
