// @flow
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import Skeleton from '@material-ui/lab/Skeleton';
import { THEME as theme } from 'GlobalStyles';
import moment from 'moment';
import ColorIndicator from 'UI/components/atoms/ColorIndicator';
import { FPCellButton } from 'UI/components/atoms/FPActionButton';
import FPAvatar from 'UI/components/atoms/FPAvatar';
import FPChip from 'UI/components/atoms/FPChip';
import FPLink from 'UI/components/atoms/FPLink';
import HealthStatusChip from 'UI/components/atoms/HealthStatusChip';
import { InterviewStatus } from 'UI/components/atoms/InterviewStatus';
import PhoneNumbersDropdown from 'UI/components/atoms/PhoneNumbersDropdown/PhoneNumbersDropdown';
import EmailStatusChip from 'UI/components/molecules/EmailStatusChip';
import EmailWithValidation from 'UI/components/molecules/EmailWithValidation';
import ReferenceStatus from 'UI/components/molecules/FPFormStatus';
import PhonePerformanceBar from 'UI/components/molecules/home/PhonePerformance/PhonePerformanceBar';
import PhonePerformancePercentage from 'UI/components/molecules/home/PhonePerformance/PhonePerformancePercentage';
import InfoLabel from 'UI/components/molecules/InfoLabel';
import RowActions from 'UI/components/molecules/RowActions';
import { DateFormats, SearchProjectPrivacyScopes } from 'UI/constants/defaults';
// OPTION
import { PaymentMode } from 'UI/constants/entityTypes';
import { SvgCheck, SvgDelete, SvgWarning } from 'UI/res';
import {
  currencyFormatter,
  formatNumber,
  getTimezoneByName,
  nestTernary,
  normalizeUrl,
  numberFormatter,
  phoneNumberFormatter,
  toLocalTime
} from 'UI/utils';
import { renderSmartTags } from 'UI/utils/sendouts';

import { TitleSubtitle } from './options';
import { GlobalSearchPersonStyles as GSPStyles, useTemplateAutocompleteStyles } from './styles';

type ColoredChipConfig = {
  labelKey: string,
  colorKey: string,
  baseClass: string,
  fallback: Object
};

type DateConfig = {
  format: string
};

type RouteLinkConfig = {
  route: string,
  text: string,
  id: string,
  customClass: String
};

type CellButtonConfig = {
  onClick: (rowData: any) => void,
  text: string
};

type GlobalCompanySearchProps = {
  displayKey: string,
  chipBaseClass: string
};

const {
  palette: { grey, brandColors, customColors }
} = theme;

const PRESET_CHIP_PROPS = {
  default: {
    variant: 'default'
  },
  warning: {
    className: 'preset-warning-chip-style',
    icon: <ErrorIcon />,
    variant: 'outlined'
  },
  error: {
    className: 'preset-error-chip',
    icon: <SvgDelete />,
    variant: 'outlined'
  }
};

export const NAChip = ({ label, hint }) => (
  <Tooltip title={hint} placement="top" arrow>
    <Chip size="small" label={label} {...PRESET_CHIP_PROPS.warning} />
  </Tooltip>
);

export const TemplateInfo = ({ opt, displayKey }) => {
  const classes = useTemplateAutocompleteStyles();

  return (
    <div>
      <span className={classes.font}>
        <b>{opt.parent_folder_name} </b> › {opt[displayKey]} <br />
      </span>
      <Typography variant="subtitle2">{opt.created_by_name}</Typography>
    </div>
  );
};

type DataTableLinkProps = RouteLinkConfig & {
  enableInTabRedirect: boolean
};

export const DataTableLink = ({
  id,
  text,
  route,
  customClass,
  enableInTabRedirect
}: DataTableLinkProps = {}) => {
  return (
    <FPLink
      target={!enableInTabRedirect ? '_blank' : null}
      onClick={e => e.stopPropagation()}
      className={customClass}
      linkProps={{
        type: 'local',
        url: id ? route.replace(':id', id) : route
      }}
    >
      {text}
    </FPLink>
  );
};

DataTableLink.defaultProps = {
  enableInTabRedirect: true
};

const CompanySearchResult = ({ title, subtitle, companyType, chipBaseClass }) => (
  <div className="w-100">
    <strong>{title}</strong>
    {chipBaseClass && companyType ? (
      <Chip
        size="small"
        label={companyType?.title}
        className={`${chipBaseClass} ${chipBaseClass}-${companyType?.id} margin-x1`}
      />
    ) : null}
    {subtitle && (
      <>
        <br />
        <span>{subtitle}</span>
      </>
    )}
  </div>
);

const SearchResult = ({ title, subtitle }) => (
  <div className="w-100">
    <strong>{title}</strong>
    {subtitle && (
      <>
        <br />
        <span>{subtitle}</span>
      </>
    )}
  </div>
);

export const Renderers = {
  activityNoteTitle: value => <strong>{value?.title} </strong>,
  arrayJoiner:
    ({ separator }) =>
    (value: Array) =>
      Array.isArray(value) && value.map(val => (!val ? '- -' : val)).join(separator),
  avatar: ({ email, initials }) => (
    <FPAvatar mode="user" userEmail={email}>
      {initials}
    </FPAvatar>
  ),
  bold: value => <strong>{value}</strong>,
  button:
    ({ onClick, text, additionalPropsBuilder, color }: CellButtonConfig) =>
    (values, params) =>
      (
        <FPCellButton
          color={color}
          text={text}
          onClick={e => {
            e.stopPropagation();
            onClick && onClick(params);
          }}
          {...(additionalPropsBuilder && typeof additionalPropsBuilder === 'function'
            ? additionalPropsBuilder(params.rowIndex)
            : {})}
        />
      ),
  chipBuilder:
    ({ labelKey, colorKey, fallback, variant }: ColoredChipConfig) =>
    value =>
      value && value[labelKey] ? (
        <FPChip
          size="small"
          label={value[labelKey]}
          color={value[colorKey]}
          textColor={
            value[colorKey] === customColors.inactiveSideBarTab ? grey[600] : brandColors.white
          }
          variant={variant}
        />
      ) : (
        fallback && <NAChip {...fallback} />
      ),
  compensation: salaryArray =>
    salaryArray && salaryArray.map(salary => currencyFormatter(salary)).join(' - '),
  compensationRange: value => {
    const maximumCompensation = value?.maximum_compensation ?? value?.[0]?.good_salary;
    const idealCompensation = value?.good_compensation ?? value?.[0]?.no_brainer_salary;
    const minimunCompensation = value?.minimum_compensation ?? value?.[0]?.minimum_salary;

    return value
      ? `HI $${maximumCompensation} IDEAL $${idealCompensation} LO $${minimunCompensation} `
      : '';
  },
  currency: value => currencyFormatter(value),
  currency_fixed: value => currencyFormatter(value, 2),
  custom:
    ({ customValue }) =>
    value =>
      value?.[customValue] ?? '',
  date: value => {
    const localTime = toLocalTime(value);
    const formattedDate = localTime && localTime.format(DateFormats.SimpleDateTime);
    return <span>{formattedDate || ''}</span>;
  },
  dateBuilder:
    ({ format }: DateConfig) =>
    value => {
      const localTime = toLocalTime(value);
      const formattedDate = localTime && localTime.format(format);
      return <span>{formattedDate || ''}</span>;
    },
  debriefingCallStatus:
    ({ entity }) =>
    value => {
      const status = value[`${entity}CallStatus`];
      const files = value[`${entity}CallFiles`];

      const fileUrl = files?.[0]?.url;

      return <InterviewStatus status={status} fileUrl={fileUrl} />;
    },
  default: value => value,
  safeDefault: value => (typeof value !== 'object' ? value : null),
  displayBasicInfoEntity: option => (
    <div>
      <b>{option.title || option.full_name || option.name}</b>
      <Typography variant="body2" component="span" color="textSecondary">
        {` / ${option.email || option.work_email || option.personal_email || ''}`}
      </Typography>
      {option.subtitle && (
        <>
          <br />
          <Typography variant="body1" component="span">
            {option.subtitle}
          </Typography>
        </>
      )}
      {option.city && (
        <>
          <br />
          <Typography variant="body2" component="span" color="textSecondary">
            {option.city}, {option.state}
          </Typography>
        </>
      )}
      {option.current_company && (
        <>
          <br />
          <Typography variant="body2" component="span" color="textSecondary">
            {option.current_company}
          </Typography>
        </>
      )}
    </div>
  ),
  email: url => <FPLink href={`mailto:${url}`} linkProps={{ type: 'external', url }} />,
  externalLink: (url, title) => (
    <FPLink
      linkProps={{
        type: 'local',
        target: '_blank',
        url
      }}
    >
      {title}
    </FPLink>
  ),
  phoneNumbers:
    ({ handleAction, phonesKey, phoneKey }) =>
    (contactValues = {}, data) =>
      (
        <PhoneNumbersDropdown
          contactValues={contactValues}
          data={data}
          onPhoneClick={handleAction}
          phonesKey={phonesKey}
          phoneKey={phoneKey}
        />
      ),
  website: () => value => {
    if (!value) return null;
    const url = normalizeUrl(value);
    return (
      <Link rel="noopener noreferrer" href={url} target="_blank">
        {url}
      </Link>
    );
  },
  fastChipBuilder:
    ({ label, labelKey, presetMode, variant, baseClass, fallback, idKey }: ColoredChipConfig) =>
    value => {
      const identifier = idKey ? value[idKey] : value?.id;
      const propsByValueType = {
        boolean: {
          hasValue: value,
          label
        },
        object: {
          hasValue: value && value[labelKey],
          label: (value && value[labelKey]) || null
        }
      };

      const currentProps = propsByValueType[typeof value];
      const presetProps = PRESET_CHIP_PROPS[presetMode] || PRESET_CHIP_PROPS.default;

      return currentProps?.hasValue ? (
        <Chip
          className={presetProps?.className || `${baseClass} ${baseClass}-${identifier}`}
          icon={presetProps?.icon}
          label={currentProps?.label}
          size="small"
          variant={variant || presetProps?.variant}
        />
      ) : (
        nestTernary(fallback, <NAChip {...fallback} />, null)
      );
    },
  validEmail: value => {
    const isValidEmail = value === true;
    const shouldRenderChip = value !== null;

    const validEmail = {
      icon: <SvgCheck />,
      className: 'chip-valid-email',
      label: 'Valid'
    };

    const invalidEmail = {
      icon: <SvgWarning />,
      className: 'chip-invalid-email',
      label: 'Invalid'
    };

    const emailValues = isValidEmail ? validEmail : invalidEmail;

    return (
      shouldRenderChip && (
        <Chip
          icon={emailValues.icon}
          variant="outlined"
          size="small"
          className={emailValues.className}
          label={emailValues.label}
        />
      )
    );
  },
  emailStatus: value => <EmailStatusChip status={value} origin="table" />,
  mainEmail: value => <EmailWithValidation email={value?.email} verdict={value?.email_verdict} />,
  secondaryEmail:
    ({ prefix }) =>
    value => {
      const PERSONAL_EMAIL_PREFIXES_KEY = {
        other: {
          email: 'other_email',
          verdict: 'personal_email_verdict'
        },
        personal: {
          email: 'personal_email',
          verdict: 'personal_email_verdict'
        }
      };
      return (
        <EmailWithValidation
          email={value?.[PERSONAL_EMAIL_PREFIXES_KEY[prefix].email]}
          verdict={value?.[PERSONAL_EMAIL_PREFIXES_KEY[prefix].verdict]}
        />
      );
    },
  formattedNumber: value => numberFormatter(value),
  goalsCount:
    ({ key, goalKey, prefix = null }) =>
    value => {
      const current = value?.[key] ?? 0;
      const goal = value?.[goalKey];
      const classname = current < goal ? 'under-goal' : '';

      return (
        <span className={classname}>
          {formatNumber(current)} {prefix}
        </span>
      );
    },
  healthStatusChip: value => (value ? <HealthStatusChip label={value} /> : null),
  referenceCheckStatus:
    ({ type, totalAnsweredQuestions, totalQuestions }) =>
    () =>
      (
        <ReferenceStatus
          status={type}
          totalQuestions={totalQuestions}
          totalAnsweredQuestions={totalAnsweredQuestions}
          shouldShowTotal
        />
      ),
  hiringAuthority:
    ({ idKey, textKey, route, customClass, enableInTabRedirect }: DataTableLink = {}) =>
    value =>
      value ? (
        <DataTableLink
          id={value?.[0]?.hiringAuthority?.[idKey] ?? value?.[idKey]}
          text={value?.[0]?.hiringAuthority?.[textKey] ?? value?.[textKey]}
          route={route}
          enableInTabRedirect={enableInTabRedirect}
          customClass={customClass}
        />
      ) : null,
  html: value => (
    <div // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: value
      }}
    />
  ),
  linkBuilder:
    ({ idKey, textKey, route, customClass, enableInTabRedirect = true }: DataTableLink = {}) =>
    value => {
      const item = value?.length >= 0 ? value[0] : value;

      return item ? (
        <DataTableLink
          id={item[idKey] || item?.id}
          text={item?.[textKey] || item?.personalInformation?.[textKey]}
          route={route}
          enableInTabRedirect={enableInTabRedirect}
          customClass={customClass}
        />
      ) : null;
    },
  conditionalLinkBuilder:
    ({
      idKey,
      textKey,
      route,
      customClass,
      enableInTabRedirect = true,
      conditionalValue,
      valueToCompare
    }: DataTableLink = {}) =>
    value => {
      const text = value?.[textKey];

      const shouldRenderLink = value?.[conditionalValue].includes(valueToCompare);

      return shouldRenderLink ? (
        <DataTableLink
          id={value?.[idKey]}
          text={text}
          route={route}
          enableInTabRedirect={enableInTabRedirect}
          customClass={customClass}
        />
      ) : (
        text
      );
    },
  location: value => `${value?.city?.title}, ${value?.city?.state?.slug ?? value?.state?.slug}`,
  phonePerformanceBar: value => <PhonePerformanceBar {...value} />,
  phonePerformance: value => <PhonePerformancePercentage {...value} />,
  percentage: value => (value ? `${value} %` : value),
  phone: value => phoneNumberFormatter(value),
  phoneBuilderRC: () => value => {
    const linkProps = {
      url: phoneNumberFormatter(value) ?? '',
      role: null
    };

    return <InfoLabel mode="ringCentral" linkProps={linkProps} />;
  },
  tenure: value => {
    const prefix = value !== 1 ? 'months' : 'month';
    return <span>{`${value} ${prefix}`}</span>;
  },
  rowActions:
    ({ actions, namespace, handleAction }) =>
    (contactValues = {}, data) =>
      actions && (
        <RowActions
          actions={actions}
          contactValues={contactValues}
          data={data}
          namespace={namespace}
          onPhoneClick={handleAction ? () => handleAction(data) : null}
        />
      ),
  skeleton: () => <Skeleton variant="rect" width="90%" height={18} />,
  smartTagsSendout: value => {
    if (!value) return null;
    const log = {
      eventDetails: value.event_details,
      eventType: {
        name: value.event
      },
      user: {
        personalInformation: {
          full_name: value.event_user_name
        }
      }
    };

    return renderSmartTags(log);
  },
  status: value =>
    value ? (
      <div>
        <ColorIndicator color={value.style_class_name || value.color || value.style} size={12} />
        {` ${value.status || value.status_group || value.title} `}
      </div>
    ) : null,

  statusBuilder:
    ({ labelKey, baseClass }: ColoredChipConfig) =>
    value =>
      value && value[labelKey] ? (
        <>
          <span className={`status-indicator ${baseClass}-${value.id}`} />
          {` ${value[labelKey]} `}
        </>
      ) : null,
  specialty: value => {
    const industry = value?.industry?.title ?? '';
    const specialty = value?.title ?? value?.specialty ?? '';
    return industry ? `${industry}: ${specialty}` : '';
  },
  visibility: value => {
    const values = {
      boolean: SearchProjectPrivacyScopes.getPrivacyState(value).title,
      string: value
    };
    return <div>{values[typeof value]}</div>;
  },
  fee: value => {
    const feePercentage = `${value?.fee_percentage ? `${value?.fee_percentage}%` : ''}`;
    const isFlatPayment = value?.fee_agreement_payment_scheme_id === PaymentMode.Flat;
    const feeAgreementValue = isFlatPayment
      ? currencyFormatter(value?.flat_fee_amount)
      : feePercentage;

    return feeAgreementValue;
  },
  interviewDate: () => value => {
    if (!value) return null;

    const getFormattedDate = baseDate => moment(baseDate).format(DateFormats.SimpleDateTime);
    const getFormattedTime = baseDate => moment(baseDate).format(DateFormats.SimpleTime);
    const getFormattedUtcDate = baseTime => moment.utc(baseTime).format(DateFormats.SimpleDateTime);

    const { date, range, timezone } = value;
    const { initials: timezoneInitials } = getTimezoneByName(timezone);

    const startDate = range ? range?.from : date;
    const endDate = range && range?.to;
    const finalDate = endDate
      ? `${getFormattedDate(startDate)} - ${getFormattedTime(endDate)}`
      : getFormattedUtcDate(startDate);

    return <Typography>{`${finalDate} (${timezoneInitials})`}</Typography>;
  },
  recruiterWithDate: value => {
    if (!value) return null;

    const { recruiter, start_date: startDate, timezone } = value;

    if (!startDate || !timezone) return recruiter;

    const formattedStartDate = moment(startDate)
      .tz(timezone)
      .format(DateFormats.MonthDayNumberYear);

    return (
      <>
        {recruiter}
        <br />
        <Typography color="textSecondary" variant="caption">
          {formattedStartDate}
        </Typography>
      </>
    );
  }
};

/**
 * @param {Number} items - number of items
 * @param {String} plural - plural termination i.e. ies, s, etc...
 * @param {String} singular - singular end of word i.e. y, e, etc...
 * @returns {String}
 */
const getSingularOrPluralEnding = (items, plural, singular = '') =>
  items !== 1 ? plural : singular;

// TODO soon to move all Autocomplete renderers here, for reuse and consistency
export const OptionRenderers = {
  default: item => (
    <>
      <b>{item.full_name} </b> {item.email ? ` / ${item.email}` : ''}
    </>
  ),
  zipLocation: item => (
    <span>
      <b>{item.title}</b>, {item.city}, {item.state}, {item.country_slug}
    </span>
  ),
  specialty: item => (
    <span>
      {item.industry} › <b>{item.title}</b>
    </span>
  ),
  subspecialty: item => (
    <span>
      {item.industry} › {item.specialty} › <b>{item.title}</b>
    </span>
  ),
  position: ({ industry, specialty, position }) => (
    <span>
      {industry} › {specialty} › <b>{position}</b>
    </span>
  ),
  title: ({ title }) => title,
  email: ({ email }) => email,
  name: ({ name, fullName }) => name || fullName,
  officeLocation: item => (
    <span>
      {item.title}
      {item.state && (
        <>
          , <strong>{item.state}</strong>
        </>
      )}
    </span>
  ),
  templatesSearch: displayKey => opt => {
    return <TemplateInfo opt={opt} displayKey={displayKey} />;
  },
  candidateInSearch: option => (
    <div>
      <strong>{option.title}</strong>
      <Typography variant="body2" component="span" color="textSecondary">
        {` ${option.email || ''}`}
      </Typography>
      <br />
      <span>{option.subtitle}</span>
    </div>
  ),
  globalCompanySearch:
    ({ displayKey, chipBaseClass }: GlobalCompanySearchProps) =>
    opt => {
      const { [displayKey]: title, subtitle, companyType } = opt || {};
      return (
        <CompanySearchResult
          title={title}
          subtitle={subtitle}
          companyType={companyType}
          chipBaseClass={chipBaseClass}
        />
      );
    },
  globalSearchDefault: displayKey => opt => {
    const { [displayKey]: title, subtitle } = opt || {};

    return <SearchResult title={title} subtitle={subtitle} />;
  },
  parentCompanySearch: displayKey => opt =>
    (
      <div>
        <strong>{opt[displayKey]}</strong>
        <br />
        <span>
          {opt.specialty} / {opt.city}, {opt.state_slug}
        </span>
      </div>
    ),
  /**
   * @param {string} displayKey - option key to display
   * @param {{ primary: string, secondary: string }} [fallback] - Object
   * that contains a primary and secondary options to replace default option
   * values when need to show another field
   * @param {Object} chipPropsBuilder
   * @returns {Node}
   */
  globalSearchPerson: (displayKey, fallback, chipPropsBuilder, showOtherEmail) => option => {
    const secondaryText = option[fallback?.secondary || 'subtitle'];
    const otherEmail = option.personal_email;

    return (
      <div style={GSPStyles.root}>
        <div style={GSPStyles.main}>
          <strong>{option[displayKey]}</strong>
          <Typography variant="body2" component="span" color="textSecondary">
            {` ${option[fallback?.primary || 'email'] || ''}`}
            {showOtherEmail && !!otherEmail && <span> / {otherEmail}</span>}
          </Typography>
          {!!secondaryText && (
            <>
              <br />
              <span>{secondaryText}</span>
            </>
          )}
        </div>
        {chipPropsBuilder && <FPChip {...chipPropsBuilder(option)} />}
      </div>
    );
  },
  searchProjects: option => {
    const {
      created_by_full_name: createdBy,
      name,
      total_candidates: cand,
      total_hiring_authorities: ha,
      total_undefined_names: names,
      updated_at: updatedAt
    } = option;
    const rendererProps = {
      title: `${name} - ${createdBy} - Latest Update ${moment(updatedAt).format(
        DateFormats.SimpleDate
      )}`,
      subtitle: `${cand} Candidate${getSingularOrPluralEnding(cand, 's')} • 
        ${ha} Hiring Authorit${getSingularOrPluralEnding(ha, 'ies', 'y')} • 
        ${names} Name${getSingularOrPluralEnding(names, 's')}`
    };
    return <TitleSubtitle {...rendererProps} />;
  },
  sendoutCandidate: option => (
    <TitleSubtitle
      title={option.candidate.personalInformation.full_name}
      subtitle={option.candidate.title}
    />
  ),
  sendoutCandidateInitials: ({ title, state, city, initials, subtitle, recruiter }) => {
    return (
      <div>
        <Typography variant="body1">{title}</Typography>
        <Typography variant="caption">
          {state}, {city}
        </Typography>
        <br />
        <Typography variant="caption" component="span" color="textSecondary">
          {subtitle}
        </Typography>
        <br />
        <Typography variant="caption" component="span" color="textSecondary">
          {recruiter} {initials || null}
        </Typography>
      </div>
    );
  },
  recruiterWithInitials: value => (
    <span>
      {value.personalInformation?.full_name} {value.initials && `(${value.initials})`}
    </span>
  ),
  displayHiringAuthority: option => {
    const email = option?.email || option?.work_email || option?.personal_email;
    return (
      <div>
        <>
          <b>{option.full_name}</b> {email ? ` / ${email}` : ''}
        </>
        {option.subtitle && (
          <>
            <br />
            {option.subtitle}
          </>
        )}
      </div>
    );
  }
};

export const Groupers = {
  byCountry: option => option.country_title,
  byIndustry: option => option.industry_title,
  bySpecialty: option => option.specialty_title,
  byRole: option => option.name_type_title,
  byTypeSendout: option => option.type.title,
  bySendoutStatuses: option => option.type.title
};

export const Selectors = {
  byId: (option: any, value: any) => option.id === value?.id
};

export const LabelRenderers = {
  title: item => item.title,
  industrySpecialty: (item: any) =>
    `${item.industry_title || item.industry?.title || ''} › ${item.title}`,
  specialty: item => `${item.industry_title} › ${item.title}`,
  subspecialty: item => `${item.specialty?.title || item.specialty} › ${item.title}`,
  zipLocation: ({ title, city }) => `${city} › ${title}`,
  radius: value => `Around ${value} mi.`,
  nameOrEmpty: item => item.name || '',
  marketCandidates: opt => `${opt.title} - ${opt.email} / ${opt.current_company}`,
  jobOrders: opt => `${opt.title} - ${opt.subtitle}`,
  /**
   * Renders a capitalized name with a regex
   */
  smartTags: opt => {
    if (!opt?.name) return '';

    const replacerFn = letter => letter.toUpperCase();
    const { name: optionName } = opt;
    const name = optionName.match(/.+(?=\()/g)[0].replace(/\b\w/g, replacerFn);
    const info = optionName.match(/\(.+\)/)[0].replace(/\(\w/, replacerFn);

    return `${name}${info}`;
  },
  feeAgreement: option => {
    if (!option) return '';

    const feeDescription =
      option.fee_percentage &&
      (!option.fee_agreement_payment_scheme_id ||
        option.fee_agreement_payment_scheme_id !== PaymentMode.Flat)
        ? `/ Percent: ${option.fee_percentage}%`
        : nestTernary(
            option.fee_agreement_payment_scheme_id === PaymentMode.Flat && !!option.flat_fee_amount,
            `/ Flat amount: ${currencyFormatter(option.flat_fee_amount)}`,
            ''
          );
    const guarantee = option.guarantee_days ? `/ ${option.guarantee_days} days` : '';
    const status = option.feeAgreementStatus
      ? `/ ${option.feeAgreementStatus.title || option.feeAgreementStatus.internal_name}`
      : '';
    return `#${option.id} ${feeDescription} ${guarantee} ${status}`.trim();
  },
  sendoutCandidate: option => `${option?.candidate?.personalInformation?.full_name}`,
  contactStatus: value => `${value.name_type_title} › ${value.title}`,
  sendoutStatus: ({ title, type }) => `${type?.title} › ${title}`,
  hiringAuthority: (value: any) => {
    const name = value?.full_name || value?.name;
    const email = value?.email || value?.work_email;
    return `${name} / ${email}`;
  }
};
