import { capitalizeName } from 'features/command-center/utils';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import moment from 'moment';
import { DateFormats } from 'UI/constants/defaults';

import { METRICS } from './OverallPerformance.constants';

const getDateRangeParams = dateRangeValue => {
  if (!dateRangeValue) return {};

  const { startDate, endDate } = dateRangeValue;

  return {
    startDate: {
      type: 'date',
      name: 'startDate',
      title: 'Start Date',
      paramName: 'start_date',
      value: moment(startDate).format(DateFormats.QueryFormat)
    },
    endDate: {
      type: 'date',
      name: 'endDate',
      title: 'End Date',
      paramName: 'end_date',
      value: moment(endDate).format(DateFormats.QueryFormat)
    }
  };
};

const tenureTitleToValueMap = {
  '0 - 6 Months': 'tenure6',
  '7 - 12 Months': 'tenure12',
  '13 - 24 Months': 'tenure24',
  '25+ Months': 'tenure25Plus'
};

const healthStatusTitleToValueMap = {
  'On Track': 'onTrack',
  Behind: 'behind',
  'At Risk': 'atRisk',
  'In Training': 'inTraining',
  'Under 3 Months': 'under3Months'
};

const mapTitleToValue = (filter, titleToValueMap) => {
  if (!filter) return {};
  const originalTitle = filter.title;
  return titleToValueMap[originalTitle] || originalTitle;
};

const getTenureParam = tenureFilter => mapTitleToValue(tenureFilter, tenureTitleToValueMap);

const getHealthStatusParam = healthStatusFilter =>
  mapTitleToValue(healthStatusFilter, healthStatusTitleToValueMap);

const filterProcessors = {
  tenure: getTenureParam,
  healthStatus: getHealthStatusParam
};

export const getFinalParams = filters => {
  if (!filters) return {};

  const dateRangeParams = getDateRangeParams(filters.dateRange?.value);

  const processedFilters = Object.fromEntries(
    Object.entries(omit(filters, 'dateRange')).map(([key, filter]) => {
      const processor = filterProcessors[key];
      if (processor && filter?.value) {
        const processedValue = processor(filter.value);
        return [key, { ...filter, value: processedValue }];
      }
      return [key, filter];
    })
  );

  const finalFilters = {
    ...processedFilters,
    ...dateRangeParams
  };

  return omitBy(finalFilters, isNil);
};

export const extendParams = teamId => {
  const baseParams = {
    metrics: METRICS
  };

  if (teamId) {
    const teamParams = {
      groupBy: 'team',
      team: teamId
    };
    return { ...baseParams, ...teamParams };
  }

  return baseParams;
};

export const formatResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    recruiterName: {
      empNo: item.empNo,
      name: capitalizeName(item.name)
    },
    team: {
      managerEmpNo: item.managerEmpNo,
      team: capitalizeName(item.team)
    },
    inMails: {
      inMails: item.inMails,
      inMailsGoals: item.inMailsGoals
    },
    avgInMails: {
      avgInMails: item.avgInMails,
      avgInMailsGoals: item.avgInMailsGoals
    },
    bulks: {
      bulks: item.bulks,
      bulksGoal: item.bulksGoal
    },
    callsTime: {
      callsTime: item.callsTime,
      callsTimeGoal: item.callsTimeGoal
    },
    calls: {
      calls: item.calls,
      callsGoal: item.callsGoal
    },
    peopleReached: {
      peopleReached: item.peopleReached,
      peopleReachedGoal: item.peopleReachedGoal
    },
    posts: {
      todayLivePosts: item.todayLivePosts,
      livePostingsDailyGoal: item.livePostingsDailyGoal
    },
    sendouts: {
      sendouts: item.sendouts,
      sendoutsGoal: item.sendoutsGoal
    },
    productionYTD: item.productionYtd,
    cashInYTD: item.cashInYtd,
    outStandingBillings: item.outstandingBillings
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};
