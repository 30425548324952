import React from 'react';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';

const { healthDistributionAtRiskConfig } = strings.commandCenter.drawers;

export const HealthDistributionAtRisk = () => {
  return <div>HealthDistributionAtRisk</div>;
};

export const HealthDistributionAtRiskConfig = {
  title: healthDistributionAtRiskConfig.title,
  subHeader: (
    <>
      <Typography
        dangerouslySetInnerHTML={{
          __html: healthDistributionAtRiskConfig.subTitle.firstPart.replace(
            '{healthStatus}',
            '<strong>At Risk</strong>'
          )
        }}
      />
      <Typography
        dangerouslySetInnerHTML={{
          __html: healthDistributionAtRiskConfig.subTitle.secondPart.replace(
            '{healthStatus}',
            '<strong>At Risk:</strong>'
          )
        }}
      />
    </>
  ),
  component: HealthDistributionAtRisk
};
